import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";
import toast from "react-hot-toast";

const token = localStorage.getItem("token");

export const fetchIMLListAsync = createAsyncThunk(
  "IML/fetchList",
  async (dataToSend) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/item/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);

export const fetchIMLListAsync1 = createAsyncThunk(
  "IML/fetchList1",
  async (dataToSend) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/item/list`,
        { ...dataToSend },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);

export const createIMLListAsync = createAsyncThunk(
  "IML/createList",
  async (inputData, { rejectWithValue, dispatch }) => {
    try {
      const { type } = inputData;
      const response = await axios.post(
        `${Base_url}/api/item/create`,
        {
          ...inputData,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      toast.success("Item Created successfully ");
      if (type == 1) {
        dispatch(fetchIMLListAsync({ type: 1 }));
      } else {
        dispatch(fetchIMLListAsync({}));
      }

      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }
);

export const deleteIMLListAsync = createAsyncThunk(
  "IML/deleteList",
  async (dataToSend, { rejectWithValue, dispatch }) => {
    try {
      const { id, type } = dataToSend;
      let url = `${Base_url}/api/item/delete/${id}`;

      // Check if type is present and equals 1
      if (type && type === 1) {
        url += `?type=1`; // Append type=1 to the URL
      }

      const response = await axios.get(url, {
        headers: { Authorization: `${token}` },
      });

      toast.success("Item Deleted successfully");
      if (type == 1) {
        dispatch(fetchIMLListAsync({ type: 1 }));
      } else {
        dispatch(fetchIMLListAsync({}));
      } // Assuming fetchIMLListAsync is defined elsewhere
      return response.data.data;
    } catch (error) {
      // Handle errors or return rejectWithValue
      // return rejectWithValue(error.response.data);
      toast.error("An error occurred");
      throw error; // Throw error to be caught in the Redux thunk caller
    }
  }
);

export const detailsIMLListAsync = createAsyncThunk(
  "IML/detailsList",
  async (dataToSend) => {
    try {
      const { id, type } = dataToSend;
      console.log(dataToSend, "DS");
      let url = `${Base_url}/api/item/details/${id}`;

      // Check if type is present and equals 1
      if (type && type === 1) {
        url += `?type=1`; // Append type=1 to the URL
      }

      const response = await axios.get(url, {
        headers: { Authorization: `${token}` },
      });

      return response.data.data;
    } catch (error) {
      // Handle errors or return rejectWithValue
      // return rejectWithValue(error.response.data);
      throw error; // Throw error to be caught in the Redux thunk
    }
  }
);

export const editIMLListAsync = createAsyncThunk(
  "IML/editList",
  async (dataToSend, { rejectWithValue, dispatch }) => {
    try {
      const { id, type } = dataToSend;
      const response = await axios.post(
        `${Base_url}/api/item/update/${id}`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (type == 1) {
        dispatch(fetchIMLListAsync({ type: 1 }));
      } else {
        dispatch(fetchIMLListAsync({}));
      }
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);

const iMLSlice = createSlice({
  name: "imlList",
  initialState: {
    itemMasterList: [],
    itemMasterDetails: {},
    status: "idle",
    error: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchIMLListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIMLListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemMasterList = action.payload;
      })
      .addCase(fetchIMLListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload
          ? action.payload?.message
          : "An error occurred";
      })
      .addCase(fetchIMLListAsync1.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIMLListAsync1.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemMasterList = action.payload;
      })
      .addCase(fetchIMLListAsync1.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload
          ? action.payload?.message
          : "An error occurred";
      })

      // create

      .addCase(createIMLListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createIMLListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createIMLListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      // delete

      .addCase(deleteIMLListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteIMLListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteIMLListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      // details

      .addCase(detailsIMLListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(detailsIMLListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemMasterDetails = action.payload;
      })
      .addCase(detailsIMLListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      //   update

      .addCase(editIMLListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editIMLListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(editIMLListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      });
  },
});

export default iMLSlice.reducer;
