import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";

const token = localStorage.getItem("token");

export const fetchPatientsListAsync = createAsyncThunk("tcPatient/fetchList", async (searchValue) => {
  try {

    // Initialize request body with empty name and mobile
    let requestBody = {
      name: '',
      mobile: ''
    };

    // Check if searchValue is a valid mobile number
    if (isMobileNumber(searchValue)) {
      requestBody.mobile = searchValue;
    } else {
      requestBody.name = searchValue;
    }

    const response = await axios.post(
      `${Base_url}/api/users/list`,
      requestBody,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error; // Rethrow the error to be caught by the caller
  }
});

// Function to check if the searchValue is a valid mobile number
function isMobileNumber(value) {
  // Check if value is a string consisting of digits
  return typeof value === 'string' && /^\d+$/.test(value);
}

export const createPatientBookingAsync = createAsyncThunk("tcPatient/createBooking", async (patientData) => {
  try {
    const response = await axios.post(
      `${Base_url}/api/users/booking/create`,
      {
        "name": patientData.patientName,
        "mobile": patientData.patientMobileNo
        // Add other necessary properties
      },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
  } catch (error) {
    throw error; 
  }
});



const TcPatientSlice = createSlice({
    name: 'tcPatient',
    initialState: {
        PatientList: [],
        PatientDetails: {},
      status: 'idle',
      error: '',
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPatientsListAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchPatientsListAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.PatientList = action.payload;
        })
        .addCase(fetchPatientsListAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload?.message || 'An error occurred';
        })
        .addCase(createPatientBookingAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createPatientBookingAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(createPatientBookingAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error?.message || 'An error occurred';
        });
        
    },
  });

  export default TcPatientSlice.reducer;