import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { hospitalitemDetails, hospitalitemEdit } from "../Store/Features/OnlyHospital/HospitalItem";
import { HospitalCategory } from "../Store/Features/OnlyHospital/hosItemCategory";

const OnlyHosEditBed = () => {
  const [inputData, setInputData] = useState({
    item_name:'',
    available_beds:'',
    total_beds: "",
    HospitalTCMarginCategory: "",
    per_day_charges: "",
    tc_margin_commission_percentage:'',
  });

  const navigate = useNavigate();

  const { id } = useParams();

  const imlCateList = useSelector((state) => state.hospitalItemList.hospitalItem);
  
  const iMlDetail = useSelector((state) => state.hospitalItemList?.hospitalitemDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(testitem())
    const dataToSend = {
      id: id,
    };
    dispatch(hospitalitemDetails(dataToSend));
  }, [dispatch, id]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    setInputData((prevData) => ({
      ...prevData,
      HospitalTCMarginCategory: event.target.value,
    }));
  };

  useEffect(() => {
    // Update inputData state when iMlDetail changes
    if (iMlDetail) {
      setInputData({
        item_name: iMlDetail.item_name,
        available_beds:iMlDetail.available_beds,
        total_beds: iMlDetail.total_beds,
        HospitalTCMarginCategory: iMlDetail.HospitalTCMarginCategory,
        per_day_charges: iMlDetail.per_day_charges,
        tc_margin_commission_percentage: iMlDetail.tc_margin_commission_percentage,
      });
    }
  }, [iMlDetail]);

  useEffect(() => {
    
    dispatch(HospitalCategory());
  }, [dispatch]);

  const handleSubmit = () => {
    // Handle submission logic here using inputData
    const dataToSend = {
      ...inputData,
      id: id,
      type: 1,
    };
    dispatch(hospitalitemEdit(dataToSend));

    navigate("/onlyHospital-Item");
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <h2 className="m0 mb10 iconTitel">
            <ContentPasteGoOutlinedIcon /> Edit Hospital Item
          </h2>
          <Paper className="customTable" sx={{ width: "100%" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow hover sx={{ "td,th": { border: 1 } }}>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Available Bed</TableCell>
                    <TableCell>Total Beds</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>TC Margin</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover sx={{ "td,th": { border: 1 } }}>
                  <TableCell>
                      <TextField
                        label="Item Name"
                        name="item_name"
                        value={inputData.item_name}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Available beds"
                        name="available_beds"
                        value={inputData.available_beds}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Total Beds"
                        name="total_beds"
                        value={inputData.total_beds}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Test
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Dr. Name"
                          value={inputData.HospitalTCMarginCategory}
                          onChange={handleCategoryChange}
                        >
                          {imlCateList?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>
                              {item.group_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    {/* <TableCell>
                      <TextField
                        label="group"
                        name="itemGroup"
                        value={inputData.itemGroup}
                        onChange={handleInputChange}
                      />
                    </TableCell> */}
                    <TableCell>
                      <TextField
                        label="Price"
                        name="per_day_charges"
                        value={inputData.per_day_charges}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="TC Margin"
                        name="tc_margin_commission_percentage"
                        value={inputData.tc_margin_commission_percentage}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        endIcon={<PostAddOutlinedIcon />}
                        onClick={handleSubmit}
                      >
                        {" "}
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OnlyHosEditBed;
