// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../../Base_url";
import axios from "axios";

const token = localStorage.getItem("token");

export const fetchHospitalTransation = createAsyncThunk(
  "transaction/fetchLists",
  async (dataToSend) => {
    try {
      // console.log(dataToSend,'DS');
      const response = await axios.post(
        `${Base_url}/api/hospital-tc-transaction/analytics/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data;
    } catch (error) {
      // throw error.response.data;
    }
  }
);



export const analyticsCSV = createAsyncThunk(
  "transaction/fetchLists",
  async (dataToSend) => {
    try {
      // console.log(dataToSend,'DS');
      const response = await axios.post(
        `${Base_url}/api/hospital-tc-transaction/analytics/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data;
    } catch (error) {
      // throw error.response.data;
    }
  }
);


export const settlementTransation = createAsyncThunk(
  "transaction/fetchListssettlement",
  async (dataToSend) => {
    try {
      // console.log(dataToSend,'DS');
      const response = await axios.post(
        `${Base_url}/api/hospital-tc-transaction/tc-settlement/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data;
    } catch (error) {
      // throw error.response.data;
    }
  }
);


export const settlementCsv = createAsyncThunk(
  "transactioncsv/fetchListssettlementcsv",
  async (dataToSend) => {
    try {
      // console.log(dataToSend,'DS');
      const response = await axios.post(
        `${Base_url}/api/hospital-tc-transaction/tc-settlement/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data;
    } catch (error) {
      // throw error.response.data;
    }
  }
);


export const debitTCTransaction = createAsyncThunk(
  "transactiondata/tcdebit",
  async (debitData, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();

      // Convert debitData object to form data
      for (const key in debitData) {
        formData.append(key, debitData[key]);
      }

      const response = await axios.post(
        `${Base_url}/api/hospital-tc-transaction/debit/transaction`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const dataToSend = {
        start_date: "",
        end_date: "",
      };

      dispatch(fetchHospitalTransation(dataToSend));
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const hospitalTransation = createSlice({
  name: "hosTransation",
  initialState: {
    hosTransationList: [],
    onlyHospitalSettlememt : [],
    onlyHoscsv:[],
    status: "idle",
    error: "",
    message: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalTransation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHospitalTransation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hosTransationList = action.payload;
      })
      .addCase(fetchHospitalTransation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      .addCase(debitTCTransaction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(debitTCTransaction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(debitTCTransaction.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload?.message;
      })


      .addCase(settlementTransation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementTransation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.onlyHospitalSettlememt = action.payload;
      })
      .addCase(settlementTransation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(settlementCsv.pending, (state) => {
        state.status = "loading";
      })
      .addCase(settlementCsv.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.onlyHoscsv = action.payload;
      })
      .addCase(settlementCsv.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


  },
});

export default hospitalTransation.reducer;
