import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchBookingsListAsync } from "../Store/Features/BookingSlice";
import { MenuItem, Select } from "@mui/material";

const HosOpenBooking = () => {
  const itemperPage = 15;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookingsList = useSelector((state) => state.booking.bookingsList);
  const [selectPatientno, setSelectPatientno] = useState("");
  const [search, setSearch] = useState("");
  const [selectPatnerno, setSelectPatnerno] = useState("");
  const [selectbookingId, setSelectBookingId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     window.location.reload();
  //   }, 10000);
  //   return () => clearInterval(intervalId);
  // }, []);

  const totaldata = useSelector(
    (state) => state.booking.totalCount?.pending_hospital_booking_count
  );


  const totalPages = totaldata && Math.ceil(totaldata / itemperPage);

  const handlepagechange = (newpage) => {
    setCurrentPage(newpage);
    const updatedSearchData = {
      page: newpage,
    };
    dispatch(fetchBookingsListAsync(updatedSearchData));
  };

  useEffect(() => {
    const fetchdata = () => {
      const dataToSend = {
        selectPatientno,
        keyword_search: search,
        selectPatnerno,
        selectbookingId,
        page: currentPage,
        limit: itemperPage,
        status: "Pending",
        type: 1,
      };
      dispatch(fetchBookingsListAsync(dataToSend));
    };
    fetchdata();
  }, [
    dispatch,
    selectPatnerno,
    search,
    selectPatientno,
    selectbookingId,
    currentPage,
    itemperPage,
  ]);

  const navigateToAddBooking = () => {
    navigate("/addHospitalBooking");
  };

  const selectpatno = (e) => {
    setSelectPatientno(e.target.value);
    setSelectPatnerno("");
  };

  const choosepatnerno = (e) => {
    setSelectPatnerno(e.target.value);
    setSelectPatientno("");
  };

  const choosebookingId = (e) => {
    setSelectBookingId(e.target.value);
    setSelectPatnerno("");
    setSelectPatientno("");
  };

  const handleDownloadPrescription = (prescriptionUrl) => {
    const link = document.createElement("a");
    link.href = prescriptionUrl;
    link.target = "_blank";
    link.click();
  };

  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
          {/* header part work start */}
          <Grid className="mb10" container>
            {/* text head start */}
            <Grid item xs={4}>
              <h2 className="m0">Hospital Open Bookings ({totaldata||0})</h2>
            </Grid>
            {/* text head end */}

            <Grid md={3}>
              <Button
                onClick={navigateToAddBooking}
                variant="contained"
                style={{background:'00c2c1'}}
                
              >
                <Link to="/addHospitalBooking"> Add Booking</Link>
              </Button>
            </Grid>

            {/* search box start */}
            <Grid item md={2}>
              <div className="searchBox">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ "aria-label": "Search" }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  {" "}
                  <SearchIcon />{" "}
                </IconButton>
              </div>
            </Grid>
            {/* search box end */}
          </Grid>
          {/* header part work end */}

          {/* table work start here */}
          <Paper className="customTable" sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 385 }}>
              <Table stickyHeader aria-label="sticky">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "red",
                      // borderBottom: "2px solid black",
                      "& th": {
                        fontSize: "0.875rem",
                        color: "#00c2c1",
                      },
                    }}
                  >
                    <TableCell>Booking ID</TableCell>
                    <TableCell>Patient Name</TableCell>
                    <TableCell>Patient Number</TableCell>
                    <TableCell>Doctor Name</TableCell>
                    <TableCell>Partner Name</TableCell>
                    <TableCell>Partner Number</TableCell>
                    <TableCell>Prescription</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Booking Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(bookingsList) && bookingsList.length > 0 ? (
                    bookingsList.some((item) => item.status === "Pending") ? (
                      bookingsList.map(
                        (item, index) =>
                          item.status === "Pending" && (
                            <TableRow hover key={index}>
                              <TableCell>{item.booking_id}</TableCell>
                              <TableCell>{item.patient_name}</TableCell>
                              <TableCell>{item.patient_number}</TableCell>
                              <TableCell>{item.doctor_name}</TableCell>
                              <TableCell>{item.partner_name}</TableCell>
                              <TableCell>
                                {maskPhoneNumber(item.partner_number)}
                              </TableCell>
                              <TableCell>
                                {item?.prescription.length === 1 ? (
                                  <IconButton
                                    color="success"
                                    variant="outlined"
                                    size="small"
                                    onClick={() =>
                                      handleDownloadPrescription(
                                        item.prescription[0]
                                      )
                                    }
                                    target="_blank"
                                    download
                                  >
                                    <CloudDownloadOutlinedIcon />
                                  </IconButton>
                                ) : item?.prescription.length === 0 ? (
                                  <IconButton
                                    color="error" // Setting the color to red
                                    variant="outlined"
                                    size="small"
                                    disabled
                                    style={{ color: "red" }}
                                  >
                                    <CloudDownloadOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <Select
                                    defaultValue=""
                                    onChange={(e) =>
                                      handleDownloadPrescription(e.target.value)
                                    }
                                    displayEmpty
                                  >
                                    <MenuItem disabled value="">
                                      Select
                                    </MenuItem>
                                    {item?.prescription.map(
                                      (prescriptionUrl, index) => (
                                        <MenuItem
                                          key={index}
                                          value={prescriptionUrl}
                                        >
                                          Prescription {index + 1}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                )}
                              </TableCell>
                              <TableCell>
                                <Link
                                  className="customBtnOutline"
                                  variant="outlined"
                                  size="small"
                                  to={`/hospital-OpenBooking-Details/${item._id}`}
                                >
                                  <RemoveRedEyeOutlinedIcon />
                                </Link>
                              </TableCell>
                              <TableCell>
                                {item?.booking_date
                                  ? new Date(
                                      item.booking_date
                                    ).toLocaleDateString("en-GB")
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          )
                      )
                    ) : (
                      <TableRow >
                        <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', height: '100px' }} colSpan={12}>
                          No Bookings Found
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>No Data Found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack className="rightPagination mt10" spacing={2}>
              <Pagination
                color="primary"
                count={totalPages}
                page={currentPage}
                shape="rounded"
                onChange={(event, value) => handlepagechange(value)}
              />
            </Stack>
          </Paper>
          {/* table work end here */}
        </CardContent>
      </Card>
      {/************ body area end here ************/}
    </>
  );
};

export default HosOpenBooking;
