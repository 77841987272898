import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { formatISO, parseISO, format } from "date-fns";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { fetchIMLListAsync1 } from "../Store/Features/IMLSlice";
import {
  createBookingAsync,
  createHosBookingAsync,
  fetchBookingsListAsync,
} from "../Store/Features/BookingSlice";
import { Autocomplete } from "@mui/material";
import {
  fetchTcPartnerList,
  createTcPartnerAsync,
} from "../Store/Features/TcPartnerSlice";
import {
  fetchPatientsListAsync,
  createPatientBookingAsync,
} from "../Store/Features/TcPatientSlice";
import {
  fetchDoctorsListAsync,
  createDoctorBookingAsync,
} from "../Store/Features/TcDoctorSlice";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { createPatientBooking, fetchPatientsList } from "../Store/Features/OnlyHospital/HosPatient";
import { createHospitalBooking } from "../Store/Features/OnlyHospital/HospitalBooking";
import { fetchDoctorList } from "../Store/Features/OnlyHospital/HospitalDoctor";
import { fetchPartnerList } from "../Store/Features/OnlyHospital/HosPartner";
import { HospitalItems } from "../Store/Features/OnlyHospital/HospitalItem";

const OnlyHosAddBooking = () => {
  const itemMaster = useSelector((state) => state.hospitalItemList.hospitalItem);
  const patnerList = useSelector((state) => state.hospitalPartnerList.hosPartner);
  const patientList = useSelector((state) => state.PatientList.PatientLists);
  const doctorList = useSelector((state) => state.hospitalDocList.hospitalDoctorList);
  const bookingStatus = useSelector((state) => state.hospitalBookingList.status);

  const [disableForm, setdisableForm] = useState({ p1: 0, p2: 0, p3: 0 });
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);
  const navigate = useNavigate();

  const [items, setItems] = useState([
    // { item_name: "", per_day_charges: "", item_discount: "", itemId: "" },
  ]);

  const [validationErrors, setValidationErrors] = useState({
    patientNameError: "",
    patientMobileNoError: "",
  });

  const [patientData, setPatientData] = useState({
    patientName: "",
    patientMobileNo: "",
  });

  const [doctorData, setDoctorData] = useState({
    DoctorName: "",
    DoctorMobileNo: "",
  });

  const [patnerData, setPatnerData] = useState({
    partnerName: "",
    partnerMobileNo: "",
    appointmentDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      timeZone: "GMT",
    }),
    city: "",
  });
  const [suggestedPatner, setSuggestedPatner] = useState([]);
  const [suggestedPatients, setSuggestedPatients] = useState([]);
  const [suggestedDoctor, setSuggestedDoctor] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tatkalCaresDiscount, setTatkalCaresDiscount] = useState(0);
  const [prescriptionfile, setPrescriptionfile] = useState(null);
  const [partnerInput, setPartnerInput] = useState("");
  const [patientInput, setPatientInput] = useState("");
  const [doctorInput, setDoctorInput] = useState("");
  const [red, setRed] = useState(false);

  const [allPartner, setAllPartner] = useState({});
  const [partnerName, setPartnerName] = useState("");
  const [partnerMobile, setPartnerMobile] = useState("");

  const handlePartnerChange = (event) => {
    const selectedPartnerId = event.target.value;
    const selectedPartner = patnerList.find(
      (item) => item._id === selectedPartnerId
    );
    if (selectedPartner) {
      setAllPartner(selectedPartner._id);
      setPartnerName(selectedPartner.name);
      setPartnerMobile(selectedPartner.mobile);
    }
  };

  const dispatch = useDispatch();

  const handleRemoveRow = (index, id1, itemId) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  useEffect(() => {
    const fetchdata = () => {
      const dataToSend = {
      };
      dispatch(HospitalItems(dataToSend));
    };
    fetchdata();
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDoctorList(doctorInput));
  }, [doctorInput]);

  useEffect(() => {
    dispatch(fetchPatientsList(patientInput));
  }, [patientInput]);

  useEffect(() => {
    dispatch(fetchPartnerList());
  }, [dispatch]);

  const handleChanges = async (event) => {
    setPatnerData({
      ...patnerData,
      appointmentDate: format(new Date(), "yyyy/MM/dd"),
    });
    const { name, value } = event.target;
    setPatnerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePrescriptionFileChange = (e) => {
    setPrescriptionFiles([...prescriptionFiles, ...e.target.files]);
  };

  const handleRemovePrescriptionFile = (index) => {
    const updatedFiles = [...prescriptionFiles];
    updatedFiles.splice(index, 1);
    setPrescriptionFiles(updatedFiles);
  };

  // patient

  const handleSelectPatner = async (selectedPatner) => {
    try {
      setPatnerData({
        partnerName: selectedPatner.label,
        partnerMobileNo: selectedPatner.value,
        appointmentDate: format(new Date(), "yyyy/MM/dd"),
      });
      setdisableForm({ ...disableForm, p2: 1 });
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const handleSelectDoctor = async (selectedDoctor) => {
    try {
      setDoctorData({
        DoctorName: selectedDoctor.label,
        DoctorMobileNo: selectedDoctor.value,
      });
      setdisableForm({ ...disableForm, p3: 1 });
    } catch (error) {
      console.error("Error fetching doctor details:", error);
    }
  };

  const handleSelectPatient = async (selectedPatient) => {
    try {
      setPatientData({
        patientName: selectedPatient.label,
        patientMobileNo: selectedPatient.value,
      });
      setdisableForm({ ...disableForm, p1: 1 });
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const handleInputChangeDoctor = (event, value) => {
    try {
      setDoctorInput(value);
      if (value === "") {
        setSuggestedDoctor([]);
        setDoctorData({ DoctorName: "", DoctorMobileNo: "" });
        setdisableForm({ ...disableForm, p3: 0 });
      } else {
        dispatch(fetchDoctorList(value))
          .then(() => {
            setSuggestedDoctor(
              doctorList.map((doctor) => ({
                label: `${doctor.name} (${doctor.mobile})`,
                value: doctor.mobile,
              }))
            );
          })
          .catch((error) => {
            console.error("Error fetching suggested doctors:", error);
          });
      }
    } catch (error) {
      console.error("Error in handleInputChangeDoctor:", error);
    }
  };

  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  const handleInputChangePatient = (event, value) => {
    try {
      setPatientInput(value);
      if (value === "") {
        setSuggestedPatients([]);
        setPatientData({ patientMobileNo: "", patientName: "" });
        setdisableForm({ ...disableForm, p1: 0 });
      } else {
        dispatch(fetchPatientsList(value))
          .then(() => {
            setSuggestedPatients(
              patientList.map((patient) => ({
                label: `${patient.name} (${patient.mobile})`,
                value: patient.mobile,
              }))
            );
          })
          .catch((error) => {
            console.error("Error fetching suggested patients:", error);
          });
      }
    } catch (error) {
      console.error("Error in handleInputChangePatient:", error);
    }
  };

  const handleInputChange = (event, value) => {
    try {
      setPartnerInput(value);
      if (value === "") {
        setSuggestedPatner([]);
        setPatnerData({ partnerName: "", partnerMobileNo: "" });
        setdisableForm({ ...disableForm, p2: 0 });
      } else {
        dispatch(fetchPartnerList(value))
          .then(() => {
            setSuggestedPatner(
              Array.isArray(patientList) &&
                patnerList.map((item) => ({
                  label: `${item.name} (${maskPhoneNumber(item.mobile)})`,
                  value: item.mobile,
                }))
            );
          })
          .catch((error) => {
            console.error("Error fetching suggested partners:", error);
          });
      }
    } catch (error) {
      console.error("Error in handleInputChange:", error);
    }
  };

  const handleDateChange = (date) => {
    // Format the date with timezone offset
    const isoDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
      timeZone: "GMT",
    });
    console.log(isoDate, "ISO Date");
    setPatnerData((prevState) => ({
      ...prevState,
      appointmentDate: isoDate,
    }));
  };

  const handleChange = (index, field, value) => {
    const updatedItems = [...items];
    const selectedMasterItem =
      Array.isArray(itemMaster) &&
      itemMaster.find((item) => item._id === value);

      if (selectedMasterItem) {
        if (selectedMasterItem.available_beds === 0) {
          toast.error("Not available beds");
        } else {
          updatedItems[index].total_beds = selectedMasterItem.total_beds;
          updatedItems[index].available_beds = selectedMasterItem.available_beds;
          updatedItems[index].hospital_item_id = selectedMasterItem._id;
          updatedItems[index].per_day_charges = selectedMasterItem.per_day_charges;
          updatedItems[index].item_name = selectedMasterItem.item_name;
          updatedItems[index].item_discount =
            selectedMasterItem.tc_margin_category.discount_percentage;
            setItems(updatedItems);
        }
      }
    

  };

  const handleAddRow = () => {
    setItems([
      ...items,
      {
        item_name: "",
        available_beds: "",
        total_beds: "",
        per_day_charges: "",
        discount_percentage: "",
        itemId: "",
      },
    ]);
  };

  const handleBookingSubmit = async () => {
    setRed(false);

    const missingFields = [];

    if (!patientData.patientName) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        patientNameError: "Patient Name is required.",
      }));
      missingFields.push("Patient Name");
    }

    if (
      !patientData.patientMobileNo ||
      !/^\d{10}$/.test(patientData.patientMobileNo)
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        patientMobileNoError: "Mobile Number should be 10 digits.",
      }));
      missingFields.push("Patient Mobile Number (10 digits)");
    }

    if (!patientData.patientName) {
      missingFields.push("Patient Name");
    }

    if (
      !patientData.patientMobileNo ||
      !/^\d{10}$/.test(patientData.patientMobileNo)
    ) {
      missingFields.push("Patient Mobile Number (10 digits)");
    }

    if (!patnerData.appointmentDate) {
      missingFields.push("Appointment Date");
    }

    if (!items.length) {
      missingFields.push("Items");
    }

    if (!prescriptionFiles.length) {
      missingFields.push("Prescription Files");
    }

    if (missingFields.length > 0) {
      const errorMessage = `required fields:\n${missingFields.join("\n")}`;
      toast.error(errorMessage);
      return;
    }

    const filteredItems = items.filter(item => item.bed_amount !== '');

    const bookingData = {
      Items: filteredItems,
      doctor_name: doctorData.DoctorName.split(" (")[0],
      doctor_number: doctorData.DoctorMobileNo,
      partner_name: partnerName,
      partner_number: partnerMobile,
      patient_name: patientData.patientName.split(" (")[0],
      patient_number: patientData.patientMobileNo,
      discount_price: tatkalCaresDiscount,
      final_amount: totalAmount - tatkalCaresDiscount,
      booking_date: patnerData.appointmentDate,
      status: "In Progress",
      payment_status: "Paid",
    };

 

    const data =  dispatch(
        createHospitalBooking({ bookingData, prescriptionFiles })
    );
    // dispatch(createDoctorBookingAsync(doctorData));
    // dispatch(createTcPartnerAsync(patnerData));
    dispatch(createPatientBooking(patientData));
   
      navigate("/onlyHospital-bookingHistory");

  };

  useEffect(() => {
    if (Array.isArray(items)) {
      const calculatedAmounts = items.reduce(
        (acc, item) => {
          acc.total += parseFloat(item.per_day_charges) || 0;
          const discountAmount =
            (parseFloat(item.item_discount) / 100) *
            (parseFloat(item.per_day_charges) || 0);
          acc.discount += discountAmount || 0;
          return acc;
        },
        { total: 0, discount: 0 }
      );

      setTotalAmount(calculatedAmounts.total);
      setTatkalCaresDiscount(calculatedAmounts.discount);
    }
  }, [items]);

  return (
    <div className="mb30">
      <Grid className="mb10" container spacing={3}>
        {/* Patient Information part start */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <h2 className="m0 mb10 iconTitel">
                    <AccountCircleOutlinedIcon /> Patient Details
                  </h2>
                </Grid>
              </Grid>

              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Search Patient</b>
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            options={suggestedPatients}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) =>
                              handleSelectPatient(value)
                            }
                            onInputChange={handleInputChangePatient}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Patient"
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Patient Name</b>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="patientName"
                            value={patientData.patientName}
                            onChange={(e) => {
                              setPatientData({
                                ...patientData,
                                patientName: e.target.value,
                              });
                              // Clear validation error for patient name
                              setValidationErrors({
                                ...validationErrors,
                                patientNameError: "",
                              });
                            }}
                            variant="outlined"
                            size="small"
                            error={!!validationErrors.patientNameError}
                            helperText={validationErrors.patientNameError}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Patient Mobile No</b>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="patientMobileNo"
                            type="Number"
                            value={patientData.patientMobileNo}
                            onChange={(e) => {
                              setPatientData({
                                ...patientData,
                                patientMobileNo: e.target.value,
                              });
                              // Clear validation error for patient mobile number
                              setValidationErrors({
                                ...validationErrors,
                                patientMobileNoError: "",
                              });
                            }}
                            variant="outlined"
                            size="small"
                            error={!!validationErrors.patientMobileNoError}
                            helperText={validationErrors.patientMobileNoError}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Prescription</b>
                        </TableCell>
                        <TableCell>
                          <input
                            type="file"
                            id="Prescription-upload-input"
                            style={{ display: "none" }}
                            multiple
                            onChange={handlePrescriptionFileChange}
                          />
                          <label htmlFor="Prescription-upload-input">
                            <Button
                              component="span"
                              endIcon={<CloudUploadOutlinedIcon />}
                              style={{
                                background: red ? "red" : "",
                                color: red ? "white" : "",
                              }}
                            >
                              Upload
                            </Button>
                          </label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Show Prescription</TableCell>
                        <TableCell
                          style={{ border: "1px solid #ccc", padding: "10px" }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {Array.isArray(prescriptionFiles) &&
                              prescriptionFiles.map((file, index) => (
                                <div
                                  key={index}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <span>{file.name}</span>
                                  <Tooltip title="Delete" placement="right">
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() =>
                                        handleRemovePrescriptionFile(index)
                                      }
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Patient Information part end */}

        {/* Partner Information part start */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <SupervisedUserCircleOutlinedIcon /> Partner Details
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Search Partner</b>
                        </TableCell>
                        <TableCell>
                          <FormControl
                            variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Partner
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={allPartner}
                              onChange={handlePartnerChange}
                              label="Partner"
                            >
                              {patnerList?.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {" "}
                                  {item.name}{" "}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>

                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Partner Name</b>
                        </TableCell>
                        <TableCell>
                          <TextField
                            id="outlined-read-only-input"
                            name="partnerName"
                            value={partnerName}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Partner Mobile No</b>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="partnerMobileNo"
                            value={partnerMobile}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>OPD Appointment Date</b>
                        </TableCell>
                        <TableCell>
                          <DatePicker
                            selected={patnerData.appointmentDate}
                            onChange={(date) => handleDateChange(date)}
                            dateFormat="yyyy-MM-dd"
                            className="custom-datepicker"
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Search Doctor</b>
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            options={suggestedDoctor}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) =>
                              handleSelectDoctor(value)
                            }
                            onInputChange={handleInputChangeDoctor}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Doctor"
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Doctor's Name</b>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="DoctorName"
                            value={doctorData.DoctorName}
                            onChange={(e) =>
                              setDoctorData({
                                ...doctorData,
                                DoctorName: e.target.value,
                              })
                            }
                            variant="outlined"
                            size="small"
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Doctor's Number</b>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="DoctorMobileNo"
                            value={doctorData.DoctorMobileNo}
                            onChange={(e) =>
                              setDoctorData({
                                ...doctorData,
                                DoctorMobileNo: e.target.value,
                              })
                            }
                            variant="outlined"
                            size="small"
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Partner Information part end */}

        {/* Add Test part start */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <ContentPasteGoOutlinedIcon /> Add Bed
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>Bed Name</TableCell>
                        <TableCell>Available beds</TableCell>
                        <TableCell>Total Beds</TableCell>
                        <TableCell>Bed Amount</TableCell>
                        <TableCell>TatkalCares Discount</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            endIcon={<PostAddOutlinedIcon />}
                            style={{ marginLeft: "15px", marginBottom: "10px" }}
                            onClick={handleAddRow}
                          >
                            {" "}
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(items) &&
                        items.map((element, index) => (
                          <TableRow
                            key={index}
                            hover
                            sx={{ "td,th": { border: 1 } }}
                          >
                            <TableCell>
                              <FormControl fullWidth>
                                <Autocomplete
                                  id={`item_name-${index}`}
                                  options={itemMaster || []}
                                  getOptionLabel={(option) => option.item_name}
                                  getOptionSelected={(option, value) => option._id === value._id}
                                  getOptionDisabled={(option) => option.available_beds === 0}
                                  value={
                                    itemMaster
                                      ? itemMaster.find(
                                          (item) => item._id === element.itemId
                                        ) || null
                                      : null
                                  }
                                  onChange={(e, value) =>
                                    handleChange(
                                      index,
                                      "item_name",
                                      value ? value._id : ""
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={element.item_name}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <h2>{element?.available_beds}</h2>
                            </TableCell>
                            <TableCell>
                              <h2>{element?.total_beds}</h2>
                            </TableCell>
                            <TableCell>
                              <h2>{element?.per_day_charges}</h2>
                            </TableCell>
                            <TableCell>
                              <h2>{element?.item_discount}%</h2>
                            </TableCell>
                            <TableCell>
                              <Button
                                color="error"
                                variant="contained"
                                endIcon={<BookmarkRemoveOutlinedIcon />}
                                onClick={() =>
                                  handleRemoveRow(index, element._id)
                                }
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Add Test part end */}

        {/* tac part start here */}
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Total Amount</b>
                      </TableCell>
                      <TableCell>{totalAmount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Tc Discount</b>
                      </TableCell>
                      <TableCell>{tatkalCaresDiscount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Final Payment</b>
                      </TableCell>
                      <TableCell>
                        {totalAmount.toFixed(2) -
                          tatkalCaresDiscount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Button
                  className="mt20"
                  variant="contained"
                  endIcon={
                    bookingStatus !== "loading" ? (
                      <SendIcon />
                    ) : (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )
                  }
                  onClick={handleBookingSubmit}
                  disabled={bookingStatus === "loading"}
                  style={{ color: bookingStatus === "loading" && "white" }}
                >
                  {bookingStatus !== "loading"
                    ? "Submit"
                    : "Submitting..."}
                </Button>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        {/* tac part end here */}
      </Grid>
    </div>
  );
};

export default OnlyHosAddBooking;
