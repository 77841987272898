import React, { useEffect, useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { HospitalItems, hospitalitemDelete } from "../Store/Features/OnlyHospital/HospitalItem";
import { bulkuploadHosital } from "../Store/Features/BulkUpload";
import CircularProgress from "@mui/material/CircularProgress";


const OnlyHosItem = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const tableRef = useRef(null);
  const iMl = useSelector((state) => state.hospitalItemList.hospitalItem);

  const status = useSelector((state) => state.hospitalItemList?.status);
   const itemperPage = 15;

   const [currentPage, setCurrentPage] = useState(1);

   const totaldata = useSelector(
    (state) => state.imlList?.itemMasterList?.item_count 
  );
  const uploadStatus = useSelector((state) => state.bulkValueHospital?.status);

  const totalPages = totaldata && Math.ceil(totaldata / itemperPage);

  const handlepagechange = (newpage) => {
    setCurrentPage(newpage);
    const updatedSearchData = {
      page: newpage,
      limit: itemperPage,
      // type:1
    };
    dispatch(HospitalItems(updatedSearchData));
  };

  useEffect(() => {
    const dataToSend={
      keyword_search:searchValue,
      page:currentPage,
      limit: itemperPage,
     
    }
    dispatch(
      HospitalItems(dataToSend)
    );
  }, [dispatch, searchValue, currentPage]);


  const navigate = useNavigate();

  const handleDelete = async (id) => {
    const confirmation = window.confirm(
      "Do you really want to cancel this booking?"
    );
    if(confirmation){
      const dataToSend ={
        id: id,
      }
  
    dispatch(hospitalitemDelete(dataToSend));
  
    //   // Fetch the updated list after deletion
    }else{
      return
    }

  };

  const handleEdit = (id) => {
    navigate(`/onlyHospital-edit/${id}`);
  };

  const handleAddNewClick = () => {
    // Navigate to the desired route
    navigate("/onlyHospitalAddBed");
  };


  const handleBulkUploadClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    // Validate file type
    if (!selectedFile || !selectedFile.name.endsWith(".xlsx")) {
      alert("Only Excel files (.xlsx) are accepted.");
      return;
    }

    // Dispatch action to bulk upload API slice
    dispatch(bulkuploadHosital(selectedFile));

    // Close the modal
    handleCloseModal();
    window.location.reload();
  };
  

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
          <h2 className="m0">Bed Information</h2>
          {/* header part work start */}
          <Grid className="mb10" container>
            {/* text head start */}
            {/* text head end */}

            {/* Radio box part start */}
            <Grid className="text-right" item md={5}>
              <Box sx={{ display: "flex", gap: "8px", color: "#fff" }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleAddNewClick}
                >
                  Add new
                </Button>
                <Button variant="contained" size="small" onClick={handleBulkUploadClick}>
                  Bulk upload
                </Button>
              </Box>
            </Grid>
            {/* Radio box part end */}

            {/* search box start */}
            <Grid item md={3}>
              <div className="searchBox">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ "aria-label": "Search" }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => dispatch(HospitalItems(searchValue))}
                >
                  {" "}
                  <SearchIcon />{" "}
                </IconButton>
              </div>
            </Grid>
            {/* search box end */}

            <Grid item xs={4} sx={{float:'right'}}>
              <DownloadTableExcel
                filename="Item master"
                sheet="Item master"
                currentTableRef={tableRef.current}
              >

                <span style={{
                  color: "#43a047",
                  cursor: 'pointer'
                }}> Export</span>
                <SaveAltIcon style={{
                  color: "#43a047",
                  cursor: 'pointer',
                  marginLeft:'2px',
                }} />
              </DownloadTableExcel>
            </Grid>
          </Grid>
          {/* header part work end */}

          {/* table work start here */}
          <Paper className="customTable" sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 385 }}>
              <Table stickyHeader aria-label="sticky" ref={tableRef}>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "red",
                      // borderBottom: "2px solid black",
                      "& th": {
                        fontSize: "0.875rem",
                        color: "#00c2c1",
                      },
                    }}
                  >
                    <TableCell>Bed Name</TableCell>
                    <TableCell>Available Beds</TableCell>
                    <TableCell>Total Beds</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Price (₹)</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>TC Margin (%)</TableCell>
                    <TableCell>Action</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === "loading" ? (
                    // Display skeleton rows when loading
                    Array.isArray(iMl) &&
                    iMl?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={6}>
                          <Skeleton
                            animation="wave"
                            height={30}
                            variant="text"
                            sx={{ fontSize: "1rem" }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : iMl && iMl.length > 0 ? (
                    // Display actual data when available
                    iMl.map((item, index) => (
                      <TableRow hover key={index}>
                         <TableCell>{item.item_name}</TableCell>
                         <TableCell>{item.available_beds}</TableCell>
                        <TableCell>{item.total_beds}</TableCell>
                        
                        <TableCell>
                          {item.tc_margin_category?.group_name}
                        </TableCell>
                        <TableCell>{item.per_day_charges}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{item.tc_margin_commission_percentage}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleEdit(item._id)}
                          >
                            <EditIcon/>
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleDelete(item._id)}
                          >
                            <DeleteIcon/>
                          </Button>
                        </TableCell>
                       
                      </TableRow>
                    ))
                  ) : (
                    // Display "No Data Found" message if no data available
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack className="rightPagination mt10" spacing={2}>
              <Pagination
                color="primary"
                count={totalPages}
                page={currentPage}
                shape="rounded"
                onChange={(event, value) => handlepagechange(value)}
              />
            </Stack>
          </Paper>
          {/* table work end here */}
        </CardContent>
      </Card>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            padding: 2,
            borderRadius: 2,
            minWidth: 300,
            maxWidth: 400,
          }}
        >
          <h2>Upload Excel File</h2>
          <input type="file" onChange={handleFileChange} />
          <Button variant="contained" onClick={handleSubmit}>
            {uploadStatus === "loading" ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Modal>
      {/************ body area end here ************/}
    </>
  );
};

export default OnlyHosItem;