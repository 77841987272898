import React, { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import "./BookingsHistory.css";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
// use for tab part start here //
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { useSelector, useDispatch } from "react-redux";
import { fetchBookingsListAsync } from "../Store/Features/BookingSlice";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { updateBookingStatusAsync } from "../Store/Features/BookingSlice";
import toast from "react-hot-toast";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";

import { fetchHospitalBooking } from "../Store/Features/OnlyHospital/HospitalBooking";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
// use for tab part end here //

const OnlyHosBookingHistory = () => {
  const dispatch = useDispatch();
  const bookingsList = useSelector((state) => state.booking.bookingsList) || [];
  const message = useSelector((state) => state?.booking?.message);
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState(7);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");
  const [status1, setStatus1] = useState("In Progress");
  const [selectPatientno, setSelectPatientno] = useState("");
  const [search, setSearch] = useState("");
  const [selectPatnerno, setSelectPatnerno] = useState("");
  const [selectbookingId, setSelectBookingId] = useState("");
  const [selecetedId, setSelectedId] = useState("");
  const [startDate, setStartDate] = useState(null); // Add startDate state
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null);
  const itemperPage = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const totaldata = useSelector(
    (state) => state.booking.totalCount?.booking_count
  );

  const totaldata1 = useSelector(
    (state) => state.booking.totalCount?.in_progress_hospital_booking_count
  );

  const totaldata2 = useSelector(
    (state) => state.booking.totalCount?.completed_hospital_booking_count
  );

  const totaldata3 = useSelector(
    (state) => state.booking.totalCount?.cancelled_hospital_booking_count
  );

  // console.log(totaldata,'tp');

  // const totalPages = useMemo(() => {
  //   switch (status1) {
  //     case "In Progress":
  //       return totaldata1 && Math.ceil(totaldata1 / itemperPage);
  //     case "Completed":
  //       return totaldata2 && Math.ceil(totaldata2 / itemperPage);
  //     default:
  //       return totaldata && Math.ceil(totaldata / itemperPage);
  //   }
  // }, [status1, totaldata, totaldata1, totaldata2]);

  let totalPages;
  if (status1 === "In Progress") {
    totalPages = totaldata1 && Math.ceil(totaldata1 / itemperPage);
  } else if (status1 === "Completed") {
    totalPages = totaldata2 && Math.ceil(totaldata2 / itemperPage);
  } else if (status1 === "Cancelled") {
    totalPages = totaldata3 && Math.ceil(totaldata2 / itemperPage);
  } else {
    totalPages = totaldata && Math.ceil(totaldata / itemperPage);
  }

  let totalPages1;
  if (status1 === "In Progress") {
    totalPages1 = totaldata1;
  } else if (status1 === "Completed") {
    totalPages1 = totaldata2;
  } else if (status1 === "Cancelled") {
    totalPages1 = totaldata3;
  } else {
    totalPages1 = totaldata;
  }

  const handlepagechange = (newpage) => {
    setCurrentPage(newpage);
    const updatedSearchData = {
      page: newpage,
      status: status1,
      limit: itemperPage,
      type: 1,
    };
    dispatch(fetchHospitalBooking(updatedSearchData));
  };



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const dataToSend = {
  //         keyword_search: search,
  //         start_date: formattedStartDate,
  //         end_date: formattedEndDate,
  //         status: status1,
  //         page: currentPage,
  //         limit: itemperPage,
  //       };
  //       dispatch(fetchHospitalBooking(dataToSend));
  //     } catch (error) {
  //       if (error.response && error.response.status === 400) {
  //         setError(400);
  //       } else {
  //         setError(null);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [dispatch, startDate, endDate, search, status1]);


  useEffect(() => {
    // Dispatch API call whenever startDate or endDate changes
    if (startDate == null && endDate == null) {
      const formattedStartDate = startDate?.toISOString().split("T")[0];
      const formattedEndDate = endDate?.toISOString().split("T")[0];
   
    //  setDataToSend({
    //   start_date: formattedStartDate,
    //   end_date: formattedEndDate,
    //   page: currentPage,
    //   limit: itemperPage,
    //   keyword_search: search,
     
    // })

     const allData = {  start_date: formattedStartDate,
      end_date: formattedEndDate,
      page: currentPage,
      limit: itemperPage,
      keyword_search: search,
      status: status1,
    }

      dispatch(
        fetchHospitalBooking(allData)
      );
    }
  }, [dispatch, endDate, currentPage,itemperPage,search,status1]);

  const handleOpen = (id, sta) => {
    if (sta === "Paid") {
      toast.error("This booking is Already Paid");
      return;
    }
    setSelectedId(id);
    setStatus(sta);
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let newStatus = "";
    switch (newValue) {
      case 0:
        newStatus = "In Progress";
        break;
      case 1:
        newStatus = "";
        break;
      case 2:
        newStatus = "Completed";
        break;
      case 3:
        newStatus = "Cancelled";
        break;
      default:
        newStatus = "In Progress";
    }
    setStatus1(newStatus); // Update the status state variable
  };

  const filterDay = (newValue) => {
    setFilter(newValue);
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split("T")[0];

    let startDate = new Date();
    startDate.setDate(currentDate.getDate() - newValue);
    startDate = startDate.toISOString().split("T")[0];

    dispatch(
      fetchHospitalBooking({
        start_date: startDate,
        end_date: endDate,
        status: status1,
        type: 1,
      })
    );
  };


  const handleDateChange = (newDates) => {
    const [start, end] = newDates;
    const formattedStartDate = dayjs(newDates[0]).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(newDates[1]).format("YYYY-MM-DD");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    // setDataToSend({
    //   start_date: formattedStartDate,
    //   end_date: formattedEndDate,
    //   page: currentPage,
    //   limit: itemperPage,
   
    // })

    const allData = {  start_date: formattedStartDate,
      end_date: formattedEndDate,
      page: currentPage,
      limit: itemperPage, 
      
    }

    dispatch(
      fetchHospitalBooking(allData)
    );
  };


  const handleReset = () => {
    const dataToSend = { 
      start_date: '',
      end_date: '',
      page: currentPage,
      limit: itemperPage,
      keyword_search: '',
      status: status1,
      type: 1,
    }
    dispatch(fetchHospitalBooking(dataToSend))
    window.location.reload();
  }


  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  const handleDownloadPrescription = (prescriptionUrl) => {
    const link = document.createElement("a");
    link.href = prescriptionUrl;
    link.target = "_blank";
    link.click();
  };

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
          <h2 className="m0  mb15 pb5 underLine">
            Hospital Bookings History ({totalPages1 || 0})
          </h2>
          {/* header part work start */}
          <Grid container className="mb10">
            {/* Radio box part and day filter start */}
            <Grid item md={12} container alignItems="center" justifyContent="space-between">
              <Grid item md={4}>
                <div className="dayFilter">
                  <div
                    className={filter === 7 ? "activeFilter" : "inactive"}
                    onClick={(e) => filterDay(7)}
                  >
                    Last 7 Days
                  </div>
                  <div
                    className={filter === 15 ? "activeFilter" : "inactive"}
                    onClick={(e) => filterDay(15)}
                  >
                    Last 15 Days
                  </div>
                  <div
                    className={filter === 30 ? "activeFilter" : "inactive"}
                    onClick={(e) => filterDay(30)}
                  >
                    Last 30 Days
                  </div>
                </div>
              </Grid>
              {/* Date picker range and search filter start */}
              <Grid item md={4}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateRangePicker"]}>
                    <DateRangePicker
                      onChange={handleDateChange} // Add onChange function
                      localeText={{ start: "start-date", end: "end-date" }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </Grid>
              {/* Date picker range and search filter end */}
              <Grid item md={4} style={{ textAlign: 'right' }}>
              <Button variant="contained" size="small" onClick={handleReset}>Reset</Button>
            </Grid>
            </Grid>
          </Grid>

          {/* header part work end */}

          {/* tab part work start here */}
          <Box className="tabBox" sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="In Progress" {...a11yProps(0)} />
                <Tab label="All" {...a11yProps(1)} />
                <Tab label="Completed" {...a11yProps(2)} />
                <Tab label="Cancelled" {...a11yProps(3)} />
              </Tabs>
            </Box>
            {/* tab item (1) start */}
            <CustomTabPanel
              value={value}
              index={0}
              style={{ padding: "0!important" }}
            >
              {/* table work start here */}
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 385 }}>
                  <Table stickyHeader aria-label="sticky">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "red",
                          // borderBottom: "2px solid black",
                          "& th": {
                            fontSize: "0.875rem",
                            color: "#00c2c1",
                          },
                        }}
                      >
                        <TableCell>Booking ID</TableCell>
                        <TableCell>Booking Date</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Patient Number</TableCell>
                        <TableCell>Doctor Name</TableCell>
                        <TableCell>Partner Name</TableCell>
                        <TableCell>Partner Number</TableCell>
                        <TableCell>Prescription</TableCell>
                        {/* <TableCell> Payment Status</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(bookingsList) &&
                      bookingsList.length > 0 ? (
                        bookingsList.map((item) => (
                          <TableRow key={item._id} hover>
                            <TableCell>{item.booking_id}</TableCell>
                            <TableCell>
                              {item?.booking_date
                                ? new Date(
                                    item.booking_date
                                  ).toLocaleDateString("en-GB")
                                : "-"}
                            </TableCell>
                            <TableCell>{item.patient_name}</TableCell>
                            <TableCell>{item.patient_number}</TableCell>
                            <TableCell>{item.doctor_name}</TableCell>
                            <TableCell>{item.partner_name}</TableCell>
                            <TableCell>
                              {maskPhoneNumber(item.partner_number)}
                            </TableCell>
                            <TableCell>
                              {item?.prescription.length === 1 ? (
                                <IconButton
                                  color="success"
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    handleDownloadPrescription(
                                      item.prescription[0]
                                    )
                                  }
                                  target="_blank"
                                  download
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : item?.prescription.length === 0 ? (
                                <IconButton
                                  color="error" // Setting the color to red
                                  variant="outlined"
                                  size="small"
                                  disabled
                                  style={{ color: "red" }}
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : (
                                <Select
                                  defaultValue=""
                                  onChange={(e) =>
                                    handleDownloadPrescription(e.target.value)
                                  }
                                  displayEmpty
                                >
                                  <MenuItem disabled value="">
                                    Select
                                  </MenuItem>
                                  {item?.prescription.map(
                                    (prescriptionUrl, index) => (
                                      <MenuItem
                                        key={index}
                                        value={prescriptionUrl}
                                      >
                                        Prescription {index + 1}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              )}
                            </TableCell>
                            {/* <TableCell>
                            <div>
                              <Button
                                onClick={() =>
                                  handleOpen(item._id, item.payment_status)
                                }
                                style={{
                                  color:
                                    item.payment_status === "Paid"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {item.payment_status}
                              </Button>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                  opacity: 1,
                                  zIndex: 50,
                                }}
                              >
                                <Box sx={style}>
                                  <Grid item xs={8}>
                                    <Card>
                                      <CardContent>
                                        <h2>Change Status</h2>
                                        <TableContainer>
                                          <Table>
                                            <TableBody>
                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Status</b>
                                                </TableCell>
                                                <TableCell>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      {status1}
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label={status1}
                                                      onChange={(e) =>
                                                        setStatus(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <MenuItem
                                                        value={"Paid"}
                                                      >
                                                        Paid
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>

                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Remark</b>
                                                </TableCell>
                                                <TableCell>
                                                  <TextField
                                                    name="remarks"
                                                    value={remarks}
                                                    variant="outlined"
                                                    sx={{ width: "100%" }}
                                                    onChange={(e) =>
                                                      setRemarks(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                          <Button
                                            onClick={() => setOpen(false)}
                                            variant="outline"
                                            style={{
                                              color: "red",
                                              marginTop: "2px",
                                            }}
                                          >
                                            cancel
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handelPaymentStatusChange()
                                            }
                                            variant="outline"
                                            color="dark"
                                            style={{
                                              float: "right",
                                              color: "#00c2c1",
                                              marginTop: "2px",
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </TableContainer>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Box>
                              </Modal>
                            </div>
                          </TableCell> */}
                            <TableCell> {item.status} </TableCell>
                            <TableCell>
                              <Link
                                className="customBtnOutline"
                                variant="outlined"
                                size="small"
                                to={`/onlyHospital-bookingHistoryDetails/${item._id}`}
                              >
                                <RemoveRedEyeOutlinedIcon />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                          No Bookings Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {/* table work end here */}
            </CustomTabPanel>
            {/* tab item (1) end */}

            {/* tab item (2) start */}
            <CustomTabPanel value={value} index={1}>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 385 }}>
                  <Table stickyHeader aria-label="sticky">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "red",
                          // borderBottom: "2px solid black",
                          "& th": {
                            fontSize: "0.875rem",
                            color: "#00c2c1",
                          },
                        }}
                      >
                        <TableCell>Booking ID</TableCell>
                        <TableCell>Booking Date</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Patient Number</TableCell>
                        <TableCell>Doctor Name</TableCell>
                        <TableCell>Partner Name</TableCell>
                        <TableCell>Partner Number</TableCell>
                        <TableCell>Prescription</TableCell>
                        {/* <TableCell> payment Status</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(bookingsList) &&
                      bookingsList.length > 0 ? (
                        bookingsList.map((item) => (
                          <TableRow key={item._id} hover>
                            <TableCell>{item.booking_id}</TableCell>
                            <TableCell>
                              {item?.booking_date
                                ? new Date(
                                    item.booking_date
                                  ).toLocaleDateString("en-GB")
                                : "-"}
                            </TableCell>
                            <TableCell>{item.patient_name}</TableCell>
                            <TableCell>{item.patient_number}</TableCell>
                            <TableCell>{item.doctor_name}</TableCell>
                            <TableCell>{item.partner_name}</TableCell>
                            <TableCell>
                              {maskPhoneNumber(item.partner_number)}
                            </TableCell>
                            <TableCell>
                              {item?.prescription.length === 1 ? (
                                <IconButton
                                  color="success"
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    handleDownloadPrescription(
                                      item.prescription[0]
                                    )
                                  }
                                  target="_blank"
                                  download
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : item?.prescription.length === 0 ? (
                                <IconButton
                                  color="error" // Setting the color to red
                                  variant="outlined"
                                  size="small"
                                  disabled
                                  style={{ color: "red" }}
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : (
                                <Select
                                  defaultValue=""
                                  onChange={(e) =>
                                    handleDownloadPrescription(e.target.value)
                                  }
                                  displayEmpty
                                >
                                  <MenuItem disabled value="">
                                    Select
                                  </MenuItem>
                                  {item?.prescription.map(
                                    (prescriptionUrl, index) => (
                                      <MenuItem
                                        key={index}
                                        value={prescriptionUrl}
                                      >
                                        Prescription {index + 1}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              )}
                            </TableCell>
                            {/* <TableCell>
                            <div>
                              <Button
                                onClick={() =>
                                  handleOpen(item._id, item.payment_status)
                                }
                                style={{
                                  color:
                                    item.payment_status === "Paid"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {item.payment_status}
                              </Button>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                  opacity: 1,
                                  zIndex: 50,
                                }}
                              >
                                <Box sx={style}>
                                  <Grid item xs={8}>
                                    <Card>
                                      <CardContent>
                                        <h2>Change Status</h2>
                                        <TableContainer>
                                          <Table>
                                            <TableBody>
                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Status</b>
                                                </TableCell>
                                                <TableCell>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      {status}
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label={status}
                                                      onChange={(e) =>
                                                        setStatus(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <MenuItem
                                                        value={"Paid"}
                                                      >
                                                        Paid
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>

                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Remark</b>
                                                </TableCell>
                                                <TableCell>
                                                  <TextField
                                                    name="remarks"
                                                    value={remarks}
                                                    variant="outlined"
                                                    sx={{ width: "100%" }}
                                                    onChange={(e) =>
                                                      setRemarks(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                          <Button
                                            onClick={() => setOpen(false)}
                                            variant="outline"
                                            style={{
                                              color: "red",
                                              marginTop: "2px",
                                            }}
                                          >
                                            cancel
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handelPaymentStatusChange()
                                            }
                                            variant="outline"
                                            color="dark"
                                            style={{
                                              float: "right",
                                              color: "#00c2c1",
                                              marginTop: "2px",
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </TableContainer>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Box>
                              </Modal>
                            </div>
                          </TableCell> */}
                            <TableCell> {item.status} </TableCell>
                            <TableCell>
                              <Link
                                className="customBtnOutline"
                                variant="outlined"
                                size="small"
                                to={`/onlyHospital-bookingHistoryDetails/${item._id}`}
                              >
                                <RemoveRedEyeOutlinedIcon />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                          No Bookings Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CustomTabPanel>
            {/* tab item (2) end */}

            {/* tab item (3) start */}
            <CustomTabPanel value={value} index={2}>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 385 }}>
                  <Table stickyHeader aria-label="sticky">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "red",
                          // borderBottom: "2px solid black",
                          "& th": {
                            fontSize: "0.875rem",
                            color: "#00c2c1",
                          },
                        }}
                      >
                        <TableCell>Booking ID</TableCell>
                        <TableCell>Booking Date</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Patient Number</TableCell>
                        <TableCell>Doctor Name</TableCell>
                        <TableCell>Partner Name</TableCell>
                        <TableCell>Partner Number</TableCell>
                        <TableCell>Prescription</TableCell>
                        {/* <TableCell>Payment Status</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(bookingsList) &&
                      bookingsList.length > 0 ? (
                        bookingsList.map((item) => (
                          <TableRow key={item._id} hover>
                            <TableCell>{item.booking_id}</TableCell>
                            <TableCell>
                              {item?.booking_date
                                ? new Date(
                                    item.booking_date
                                  ).toLocaleDateString("en-GB")
                                : "-"}
                            </TableCell>
                            <TableCell>{item.patient_name}</TableCell>
                            <TableCell>{item.patient_number}</TableCell>
                            <TableCell>{item.doctor_name}</TableCell>
                            <TableCell>{item.partner_name}</TableCell>
                            <TableCell>
                              {maskPhoneNumber(item.partner_number)}
                            </TableCell>
                            <TableCell>
                              {item?.prescription.length === 1 ? (
                                <IconButton
                                  color="success"
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    handleDownloadPrescription(
                                      item.prescription[0]
                                    )
                                  }
                                  target="_blank"
                                  download
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : item?.prescription.length === 0 ? (
                                <IconButton
                                  color="error" // Setting the color to red
                                  variant="outlined"
                                  size="small"
                                  disabled
                                  style={{ color: "red" }}
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : (
                                <Select
                                  defaultValue=""
                                  onChange={(e) =>
                                    handleDownloadPrescription(e.target.value)
                                  }
                                  displayEmpty
                                >
                                  <MenuItem disabled value="">
                                    Select
                                  </MenuItem>
                                  {item?.prescription.map(
                                    (prescriptionUrl, index) => (
                                      <MenuItem
                                        key={index}
                                        value={prescriptionUrl}
                                      >
                                        Prescription {index + 1}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              )}
                            </TableCell>
                            {/* <TableCell>
                            <div>
                              <Button
                                onClick={() =>
                                  handleOpen(item._id, item.payment_status)
                                }
                                style={{
                                  color:
                                    item.payment_status === "Paid"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {item.payment_status}
                              </Button>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                  opacity: 1,
                                  zIndex: 50,
                                }}
                              >
                                <Box sx={style}>
                                  <Grid item xs={8}>
                                    <Card>
                                      <CardContent>
                                        <h2>Change Status</h2>
                                        <TableContainer>
                                          <Table>
                                            <TableBody>
                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Status</b>
                                                </TableCell>
                                                <TableCell>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      {status}
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label={status}
                                                      onChange={(e) =>
                                                        setStatus(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <MenuItem
                                                        value={"Paid"}
                                                      >
                                                        Paid
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>

                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Remark</b>
                                                </TableCell>
                                                <TableCell>
                                                  <TextField
                                                    name="remarks"
                                                    value={remarks}
                                                    variant="outlined"
                                                    sx={{ width: "100%" }}
                                                    onChange={(e) =>
                                                      setRemarks(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                          <Button
                                            onClick={() => setOpen(false)}
                                            variant="outline"
                                            style={{
                                              color: "red",
                                              marginTop: "2px",
                                            }}
                                          >
                                            cancel
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handelPaymentStatusChange()
                                            }
                                            variant="outline"
                                            color="dark"
                                            style={{
                                              float: "right",
                                              color: "#00c2c1",
                                              marginTop: "2px",
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </TableContainer>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Box>
                              </Modal>
                            </div>
                          </TableCell> */}
                            <TableCell> {item.status} </TableCell>
                            <TableCell>
                              <Link
                                className="customBtnOutline"
                                variant="outlined"
                                size="small"
                                to={`/onlyHospital-bookingHistoryDetails/${item._id}`}
                              >
                                <RemoveRedEyeOutlinedIcon />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                          No Bookings Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CustomTabPanel>
            {/* tab item (3) end */}

            {/* tab item (4) start */}
            <CustomTabPanel value={value} index={3}>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 385 }}>
                  <Table stickyHeader aria-label="sticky">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "red",
                          // borderBottom: "2px solid black",
                          "& th": {
                            fontSize: "0.875rem",
                            color: "#00c2c1",
                          },
                        }}
                      >
                        <TableCell>Booking ID</TableCell>
                        <TableCell>Booking Date</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Patient Number</TableCell>
                        <TableCell>Doctor Name</TableCell>
                        <TableCell>Partner Name</TableCell>
                        <TableCell>Partner Number</TableCell>
                        <TableCell>Prescription</TableCell>
                        {/* <TableCell> payment Status</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(bookingsList) &&
                      bookingsList.length > 0 ? (
                        bookingsList.map((item) => (
                          <TableRow key={item._id} hover>
                            <TableCell>{item.booking_id}</TableCell>
                            <TableCell>
                              {item?.booking_date
                                ? new Date(
                                    item.booking_date
                                  ).toLocaleDateString("en-GB")
                                : "-"}
                            </TableCell>
                            <TableCell>{item.patient_name}</TableCell>
                            <TableCell>{item.patient_number}</TableCell>
                            <TableCell>{item.doctor_name}</TableCell>
                            <TableCell>{item.partner_name}</TableCell>
                            <TableCell>
                              {maskPhoneNumber(item.partner_number)}
                            </TableCell>
                            <TableCell>
                              {item?.prescription.length === 1 ? (
                                <IconButton
                                  color="success"
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    handleDownloadPrescription(
                                      item.prescription[0]
                                    )
                                  }
                                  target="_blank"
                                  download
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : item?.prescription.length === 0 ? (
                                <IconButton
                                  color="error" // Setting the color to red
                                  variant="outlined"
                                  size="small"
                                  disabled
                                  style={{ color: "red" }}
                                >
                                  <CloudDownloadOutlinedIcon />
                                </IconButton>
                              ) : (
                                <Select
                                  defaultValue=""
                                  onChange={(e) =>
                                    handleDownloadPrescription(e.target.value)
                                  }
                                  displayEmpty
                                >
                                  <MenuItem disabled value="">
                                    Select
                                  </MenuItem>
                                  {item?.prescription.map(
                                    (prescriptionUrl, index) => (
                                      <MenuItem
                                        key={index}
                                        value={prescriptionUrl}
                                      >
                                        Prescription {index + 1}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              )}
                            </TableCell>
                            {/* <TableCell>
                            <div>
                              <Button
                                onClick={() =>
                                  handleOpen(item._id, item.payment_status)
                                }
                                style={{
                                  color:
                                    item.payment_status === "Paid"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {item.payment_status}
                              </Button>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                                  opacity: 1,
                                  zIndex: 50,
                                }}
                              >
                                <Box sx={style}>
                                  <Grid item xs={8}>
                                    <Card>
                                      <CardContent>
                                        <h2>Change Status</h2>
                                        <TableContainer>
                                          <Table>
                                            <TableBody>
                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Status</b>
                                                </TableCell>
                                                <TableCell>
                                                  <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                      {status}
                                                    </InputLabel>
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      label={status}
                                                      onChange={(e) =>
                                                        setStatus(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <MenuItem
                                                        value={"Paid"}
                                                      >
                                                        Paid
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>

                                              <TableRow
                                                sx={{
                                                  "td,th": { border: 1 },
                                                }}
                                              >
                                                <TableCell>
                                                  <b>Remark</b>
                                                </TableCell>
                                                <TableCell>
                                                  <TextField
                                                    name="remarks"
                                                    value={remarks}
                                                    variant="outlined"
                                                    sx={{ width: "100%" }}
                                                    onChange={(e) =>
                                                      setRemarks(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                          <Button
                                            onClick={() => setOpen(false)}
                                            variant="outline"
                                            style={{
                                              color: "red",
                                              marginTop: "2px",
                                            }}
                                          >
                                            cancel
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              handelPaymentStatusChange()
                                            }
                                            variant="outline"
                                            color="dark"
                                            style={{
                                              float: "right",
                                              color: "#00c2c1",
                                              marginTop: "2px",
                                            }}
                                          >
                                            Save
                                          </Button>
                                        </TableContainer>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Box>
                              </Modal>
                            </div>
                          </TableCell> */}
                            <TableCell> {item.status} </TableCell>
                            <TableCell>
                              <Link
                                className="customBtnOutline"
                                variant="outlined"
                                size="small"
                                to={`/onlyHospital-bookingHistoryDetails/${item._id}`}
                              >
                                <RemoveRedEyeOutlinedIcon />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                          No Bookings Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CustomTabPanel>

            <Stack className="rightPagination mt10" spacing={2}>
              <Pagination
                color="primary"
                count={totalPages}
                page={currentPage}
                shape="rounded"
                onChange={(event, value) => handlepagechange(value)}
              />
            </Stack>
            {/* tab item (3) end */}
          </Box>
          {/* tab part work end here */}
        </CardContent>
      </Card>
      {/************ body area end here ************/}
    </>
  );
};

export default OnlyHosBookingHistory;
