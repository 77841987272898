// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 900px !important;
    gap: 20px;
    margin: auto;
    height: 100%;
    background-color: #fff;
    border-radius: 10px !important;
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.header-text {
    text-align: center;
    font-size: 26px;
    margin: 2px;
}
.sub-header-text {
    text-align: center;
    margin: 2px;
    font-size: 14px;
    margin-bottom: 20px;
}
.login-btn {
    display: flex;
    width: 200px;
    background: #039d9c;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top:10px;
}

.LoginCard {
    padding: 0.75rem !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
    margin: 1rem !important;
    border-radius: 10px !important;
    width: 300px;
    margin-top: 20px;

}`, "",{"version":3,"sources":["webpack://./src/login/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IACrB,uBAAuB;IACvB,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,8BAA8B;IAC9B,4EAA4E;AAChF;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,uFAAuF;IACvF,uBAAuB;IACvB,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;;AAEpB","sourcesContent":[".cards {\n    display: flex;\n    justify-content: space-between;\n    align-items: baseline;\n    width: 900px !important;\n    gap: 20px;\n    margin: auto;\n    height: 100%;\n    background-color: #fff;\n    border-radius: 10px !important;\n    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);\n}\n\n.header-text {\n    text-align: center;\n    font-size: 26px;\n    margin: 2px;\n}\n.sub-header-text {\n    text-align: center;\n    margin: 2px;\n    font-size: 14px;\n    margin-bottom: 20px;\n}\n.login-btn {\n    display: flex;\n    width: 200px;\n    background: #039d9c;\n    color: #ffffff;\n    justify-content: center;\n    align-items: center;\n    padding: 6px;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-bottom: 20px;\n    margin-top:10px;\n}\n\n.LoginCard {\n    padding: 0.75rem !important;\n    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;\n    margin: 1rem !important;\n    border-radius: 10px !important;\n    width: 300px;\n    margin-top: 20px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
