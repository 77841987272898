import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  analyticsAll,
  exportAnalytics,
  fetchTCTransactionsListAsync,
} from "../Store/Features/Tctransaction";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";

const TcAnalytics = () => {
  const dispatch = useDispatch();

  const AnalyticsList =
    useSelector((state) => state.tctransaction.transactionsList?.data) || [];

  const AnalyticsCSV =
    useSelector((state) => state.tctransaction.analyticsCSV?.data) || [];

  const AnalyticsAllData =
    useSelector((state) => state.tctransaction.analyticsalldata?.data) || [];

  const status = useSelector(
    (state) => state.tctransaction.analyticsCSV.status
  );



  const [totalBillingAmount, setTotalBillingAmount] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalTCRevenue, setTotalTCRevenue] = useState(0);
  const [filter, setFilter] = useState(7);
  const [startDate, setStartDate] = useState(null); // Add startDate state
  const [endDate, setEndDate] = useState(null);
  const [customDatePickerOpen, setCustomDatePickerOpen] = useState(false);
  const [dataToSend, setDataToSend] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const itemperPage = 15;

  const totaldata = useSelector(
    (state) => state.tctransaction?.transactionsList?.partner_transactions_count
  );

  const tc_analytics = useSelector((state) => state.tctransaction.transactionsList);

  const totaldatas = useSelector(
    (state) => state.tctransaction?.transactionsList
  );

  const totalPages = totaldata && Math.ceil(totaldata / itemperPage);

  const handlepagechange = (newpage) => {
    setCurrentPage(newpage);
    const updatedSearchData = {
      ...dataToSend,
      page: newpage,
      limit: itemperPage,
    };
    dispatch(exportAnalytics(updatedSearchData));
  };

  // useEffect(() => {
  //   dispatch(exportAnalytics());
  // }, []);

  useEffect(() => {
    // Dispatch API call whenever startDate or endDate changes
    if (startDate == null && endDate == null) {
      const formattedStartDate = startDate?.toISOString().split("T")[0];
      const formattedEndDate = endDate?.toISOString().split("T")[0];

      setDataToSend({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        page: currentPage,
        limit: itemperPage,
        keyword_search: search,
      });

      const allData = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        page: currentPage,
        limit: itemperPage,
        keyword_search: search,
      };

      dispatch(exportAnalytics(allData));
    }
  }, [dispatch, endDate, currentPage, itemperPage, search]);

  const filterDay = (newValue) => {
    setFilter(newValue);
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split("T")[0];

    let startDate = new Date();
    startDate.setDate(currentDate.getDate() - newValue);
    startDate = startDate.toISOString().split("T")[0];

    setDataToSend({
      start_date: startDate,
      end_date: endDate,
      page: currentPage,
      limit: itemperPage,
    });

    const allData = {
      start_date: startDate,
      end_date: endDate,
      page: currentPage,
      limit: itemperPage,
    };

    dispatch(exportAnalytics(allData));
  };

  useEffect(() => {
    dispatch(analyticsAll());
  }, []);

  const handleDateChange = (newDates) => {
    const [start, end] = newDates;
    const formattedStartDate = dayjs(newDates[0]).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(newDates[1]).format("YYYY-MM-DD");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setDataToSend({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      page: currentPage,
      limit: itemperPage,
    });

    const allData = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      page: currentPage,
      limit: itemperPage,
    };

    dispatch(exportAnalytics(allData));
  };

  console.log("analytics CSV", AnalyticsAllData);

  useEffect(() => {
    dispatch(fetchTCTransactionsListAsync());
  }, []);

  const excludedKeys = [
    "_id",
    "partnerId",
    "attachment",
    "booking_details",
    "partner_details",
    "booking_id",
    "remarks",
    "payment",
  ];

  // Filter out excluded keys
  const filteredData = AnalyticsAllData?.map((item) => {
    const filteredItem = { ...item };
    excludedKeys.forEach((key) => delete filteredItem[key]);
    return filteredItem;
  });

  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
          <Grid container alignItems="baseline">
            <Grid item xs={9}>
              <h2 className="m0"> Diagnostics Analytics </h2>
            </Grid>
            <Grid item xs={1}>
              <Button variant="contained" size="small" onClick={handleReset}>
                Reset
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                style={{
                  backgroundColor: "#43a047",
                  color: "#ffffff",
                  padding: "5px 8px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CSVLink
                  style={{
                    color: "#ffffff",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                  data={filteredData}
                  title={`Diagnostic Analytics ${tc_analytics?.diagnostic_name}`}
                  filename={`Diagnostic Analytics ${tc_analytics?.diagnostic_name}`}
                >
                  <span>Export</span>
                  <SaveAltIcon />
                </CSVLink>
              </Button>
            </Grid>
          </Grid>
          {/* header part work start */}
          <Grid className="mb10" container alignItems="baseline">
            {/* text head start */}
            {/* text head end */}

            {/* Radio box part start */}

            <Grid item md={4}>
              <div className="dayFilter">
                <div
                  className={filter === 7 ? "activeFilter" : "inactive"}
                  onClick={(e) => filterDay(7)}
                >
                  Last 7 Days
                </div>
                <div
                  className={filter === 15 ? "activeFilter" : "inactive"}
                  onClick={(e) => filterDay(15)}
                >
                  Last 15 Days
                </div>
                <div
                  className={filter === 30 ? "activeFilter" : "inactive"}
                  onClick={(e) => filterDay(30)}
                >
                  Last 30 Days
                </div>
              </div>
            </Grid>

            <Grid item md={4}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateRangePicker"]}>
                    <DateRangePicker
                      onChange={handleDateChange} // Add onChange function
                      localeText={{ start: "start-date", end: "end-date" }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </Grid>

            {/* Radio box part end */}

            {/* search box start */}
            <Grid item md={3}>
              <div className="searchBox">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search...."
                  inputProps={{ "aria-label": "Search" }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  {" "}
                  <SearchIcon />{" "}
                </IconButton>
              </div>
            </Grid>
            {/* search box end */}
          </Grid>
          {/* header part work end */}

          {/* table work start here */}
          <Paper className="customTable" sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 385 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "red",
                      // borderBottom: "2px solid black",
                      "& th": {
                        fontSize: "0.875rem",
                        color: "#00c2c1",
                      },
                    }}
                  >
                    <TableCell>Booking ID</TableCell>
                    <TableCell>Patient Name</TableCell>
                    <TableCell>Patient Number</TableCell>
                    <TableCell>Doctor Name</TableCell>
                    <TableCell>Partner Name</TableCell>
                    <TableCell>Partner Number</TableCell>
                    <TableCell>Payment Status</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                    <TableCell>Billing Amount</TableCell>
                    <TableCell>TC Revenue</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === "loading" ? ( // Render CircularProgress when status is 'loading'
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : Array.isArray(AnalyticsCSV) &&
                    AnalyticsCSV?.length > 0 ? ( // Check if there is data to display
                    AnalyticsCSV?.map((item) =>
                      item?.booking_id ? (
                        <TableRow key={item?._id} hover>
                          <TableCell>{item?.booking_id}</TableCell>
                          <TableCell>{item?.patient_name}</TableCell>
                          <TableCell>{item?.patient_number}</TableCell>
                          <TableCell>{item?.doctor_name}</TableCell>
                          <TableCell>{item?.partner_name}</TableCell>
                          <TableCell>
                            {maskPhoneNumber(item?.partner_number)}
                          </TableCell>
                          <TableCell>{item?.payment_status}</TableCell>
                          {/* <TableCell>{item?.booking_details.status}</TableCell> */}
                          <TableCell>{item?.billing_amount}</TableCell>
                          <TableCell>{item?.tc_revenue}</TableCell>
                        </TableRow>
                      ) : null
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack className="rightPagination mt10" spacing={2}>
              <Pagination
                color="primary"
                count={totalPages}
                page={currentPage}
                shape="rounded"
                onChange={(event, value) => handlepagechange(value)}
              />
            </Stack>
          </Paper>
          {/* table work end here */}
        </CardContent>

        <Grid item xs={4}>
          <Card>
            <CardContent>
              <h2>Summary:</h2>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Total Revenue Earned By Tatkal Cares</b>
                      </TableCell>
                      <TableCell>
                        {totaldatas?.partner_total_commission?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b style={{ color: "#43a047" }}>
                          Total Amount Paid to Tatkal Cares
                        </b>
                      </TableCell>
                      <TableCell style={{ color: "#43a047" }}>
                        {totaldatas?.total_debit?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b style={{ color: "red" }}>Outstanding Balance</b>
                      </TableCell>
                      <TableCell style={{ color: "red" }}>
                        {(
                          (totaldatas?.partner_total_commission || 0) -
                          (totaldatas?.total_debit || 0)
                        ).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* <Button className='mt20' variant="contained" endIcon={<SendIcon />}>Submit</Button> */}
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Card>
      {/************ body area end here ************/}
    </>
  );
};

export default TcAnalytics;