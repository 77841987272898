import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import UserPic from "../images/user.jpg";
import {
  getUserDetailsAsync,
  updateUserDetailsAsync,
} from "../Store/Features/AuthSlice";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({});
  const [editedPassword, setEditedPassword] = useState("");

  useEffect(() => {
      dispatch(getUserDetailsAsync(token));
  }, [dispatch]);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedFields({
      email: user?.email,
      name: user?.name,
      license_no: user?.license_no,
      mobile: user?.mobile,
      address: user?.address,
      city: user?.city,
    });
    setEditedPassword(""); // Clear password field on edit
  };

  const handleInputChange = (field, value) => {
    setEditedFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const id = user?._id;

  const handleSaveChanges = () => {
    const dataToSend = {
      ...editedFields,
      password: editedPassword,
      id: id,
    };
    dispatch(updateUserDetailsAsync(dataToSend));
    setIsEditing(false);
  };

  return (
    <div>
      <Grid className="mb10" container>
        <Grid item xs={7}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <RecentActorsOutlinedIcon /> My Profile
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Email Id.</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedFields.email}
                              onChange={(e) =>
                                handleInputChange("email", e.target.value)
                              }
                            />
                          ) : (
                            user?.email
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 150 }}>
                          <b>Clinic Name</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedFields.name}
                              onChange={(e) =>
                                handleInputChange("name", e.target.value)
                              }
                            />
                          ) : (
                            user?.name
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>License No.</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedFields.license_no}
                              onChange={(e) =>
                                handleInputChange("license_no", e.target.value)
                              }
                            />
                          ) : (
                            user?.license_no
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Contact No.</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedFields.mobile}
                              onChange={(e) =>
                                handleInputChange("mobile", e.target.value)
                              }
                            />
                          ) : (
                            user?.mobile
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Address</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedFields.address}
                              onChange={(e) =>
                                handleInputChange("address", e.target.value)
                              }
                            />
                          ) : (
                            user?.address
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>City</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="text"
                              value={editedFields.city}
                              onChange={(e) =>
                                handleInputChange("city", e.target.value)
                              }
                            />
                          ) : (
                            user?.city
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Password</b>
                        </TableCell>
                        <TableCell>
                          {isEditing ? (
                            <input
                              type="password"
                              value={editedPassword}
                              onChange={(e) =>
                                setEditedPassword(e.target.value)
                              }
                            />
                          ) : (
                            "********" // Display masked password 
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Photo</b>
                        </TableCell>
                        <TableCell>
                          <img src={UserPic} width="64" alt="" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="mt20">
                  {isEditing ? (
                    <>
                      <Button
                        color="error"
                        variant="outlined"
                        size="small"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="success"
                        variant="outlined"
                        size="small"
                        style={{ float: "right" }}
                        onClick={handleSaveChanges}
                      >
                        Save Changes
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="success"
                      variant="outlined"
                      size="small"
                      startIcon={<DriveFileRenameOutlineOutlinedIcon />}
                      onClick={handleEditClick}
                    >
                      Edit Profile
                    </Button>
                  )}
                </div>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyProfile;
