// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../../Base_url";
import axios from "axios";

const token = localStorage.getItem('token')

export const HospitalCategory = createAsyncThunk('onlyhospital/fetchListcate', async () => {
    try {
      const response = await axios.get(`${Base_url}/api/hospital-tc-margin/list`, {
        headers: { Authorization: `${token}` },
      });
      return response.data.data;
    } catch (error) {
    //   return rejectWithValue(error.response.data);
    }
  });





  const hosItemCategory = createSlice({
    name: 'hospitalCategoryList',
    initialState:{
        hosCategory : [],
        status: 'idle',
        error: '',
    },

    reducers: {},

    extraReducers: (builder) => {
        builder
          .addCase(HospitalCategory.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(HospitalCategory.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.hosCategory = action.payload;
          })
          .addCase(HospitalCategory.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload?.message || 'An error occurred';
          })
      },
    

  })


  export default hosItemCategory.reducer;