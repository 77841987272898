import React, { useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useParams, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBookingDetailsAsync,
  removeBookingItemAsync,
  addBookingItemAsync,
  deleteStatusAsync,
  updateBookingAsyncOpenBooking,
} from "../Store/Features/BookingSlice";
import {
  fetchTcPartnerList,
  createTcPartnerAsync,
} from "../Store/Features/TcPartnerSlice";
import {
  fetchDoctorsListAsync,
  createDoctorBookingAsync,
} from "../Store/Features/TcDoctorSlice";
import toast from "react-hot-toast";
import { fetchIMLListAsync1 } from "../Store/Features/IMLSlice";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, TextField } from "@mui/material";

const OpenBookingDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [items, setItems] = useState([
    // {item_name: "", item_price: "", item_discount: "", itemId: "" },
  ]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [tatkalCaresDiscount, setTatkalCaresDiscount] = useState(0);
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);
  const [red, setRed] = useState(false);
  const navigate = useNavigate();

  const bookingDetail = useSelector((state) => state.booking?.bookingDetails);
  const patnerList = useSelector((state) => state.tcPartner.partnerList);
  const doctorList = useSelector((state) => state.tcDoctor.DoctorList);
  const itemMaster = useSelector((state) => state.imlList?.itemMasterList);
  const message = useSelector((state) => state.booking?.message);

  const [allPartner, setAllPartner] = useState({});
  const [partnerName, setPartnerName] = useState("");
  const [partnerMobile, setPartnerMobile] = useState("");
  const [doctorInput, setDoctorInput] = useState("");
  const [disableForm, setdisableForm] = useState({ p1: 0, p2: 0, p3: 0 });
  const [suggestedDoctor, setSuggestedDoctor] = useState([]);

  console.log(bookingDetail,'bookingDetail')

  const handlePartnerChange = (event) => {
    setValidationErrors({});
    const selectedPartnerId = event.target.value;
    const selectedPartner = patnerList.find(
      (item) => item._id === selectedPartnerId
    );
    if (selectedPartner) {
      setAllPartner(selectedPartner._id);
      setPartnerName(selectedPartner.name);
      setPartnerMobile(selectedPartner.mobile);
    }
  };

  const [validationErrors, setValidationErrors] = useState({
    partnerNameError: "",
  });

  const [doctorData, setDoctorData] = useState({
    DoctorName: "",
    DoctorMobileNo: "",
  });

  useEffect(() => {
    dispatch(fetchDoctorsListAsync(doctorInput));
  }, [doctorInput]);

  useEffect(() => {
    dispatch(fetchTcPartnerList());
  }, [dispatch]);

  const handleSelectDoctor = async (selectedDoctor) => {
    try {
      setDoctorData({
        DoctorName: selectedDoctor.label,
        DoctorMobileNo: selectedDoctor.value,
      });
      setdisableForm({ ...disableForm, p3: 1 });
    } catch (error) {
      console.error("Error fetching doctor details:", error);
    }
  };

  const handleInputChangeDoctor = (event, value) => {
    try {
      setDoctorInput(value);
      if (value === "") {
        setSuggestedDoctor([]);
        setDoctorData({ DoctorName: "", DoctorMobileNo: "" });
        setdisableForm({ ...disableForm, p3: 0 });
      } else {
        dispatch(fetchDoctorsListAsync(value))
          .then(() => {
            setSuggestedDoctor(
              doctorList.map((doctor) => ({
                label: `${doctor.name} (${doctor.mobile})`,
                value: doctor.mobile,
              }))
            );
          })
          .catch((error) => {
            console.error("Error fetching suggested doctors:", error);
          });
      }
    } catch (error) {
      console.error("Error in handleInputChangeDoctor:", error);
    }
  };

  // console.log(items,'item list 1');

  useEffect(() => {
    const dataToSend = {
      id: id,
    };
    dispatch(fetchBookingDetailsAsync(dataToSend));
  }, [dispatch, id]);

  useEffect(() => {
    const fetchdata = () => {
      dispatch(fetchIMLListAsync1());
    };
    fetchdata();
  }, [dispatch, id]);

  const {
    Items,
    booking_date,
    booking_id,
    city,
    discount_price,
    doctor_name,
    doctor_number,
    final_amount,
    partner_name,
    partner_number,
    patient_name,
    patient_number,
    prescription,
    remarks,
    report,
    status,
  } = bookingDetail || {};

  useEffect(() => {
    if (Array.isArray(items)) {
      const calculatedAmounts = items.reduce(
        (acc, item) => {
          acc.total += parseFloat(item.item_price) || 0;
          const discountAmount =
            (parseFloat(item.item_discount) / 100) *
            (parseFloat(item.item_price) || 0);
          acc.discount += discountAmount || 0;
          return acc;
        },
        { total: 0, discount: 0 }
      );

      setTotalAmount(calculatedAmounts.total);
      setTatkalCaresDiscount(calculatedAmounts.discount);
    }
  }, [items]);

  const handleCancel = () => {
    const confirmation = window.confirm(
      "Do you really want to cancel this booking?"
    );

    if (confirmation) {
      dispatch(deleteStatusAsync({ selecetedId: id, status: "Cancelled" }));
      navigate("/bookingsHistory");
    } else {
    }
  };

  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  useEffect(() => {
    if (bookingDetail && bookingDetail.Items) {
      setItems(
        bookingDetail.Items.map((item) => ({
          _id: item._id,
          item_name: item.item_name,
          item_price: item.item_price,
          item_discount: item.item_discount,
          itemId: item.itemId,
        }))
      );
    }
  }, [bookingDetail]);

  const handlePrescriptionFileChange = (e) => {
    const files = e.target.files;
    const newFiles = [...prescriptionFiles];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= 10 * 1024 * 1024) { // 5MB limit
        newFiles.push(files[i]);
      } else {
        toast.error("File size should be less than 10MB ");
      }
    }
    setPrescriptionFiles(newFiles);
  };

  const handleRemovePrescriptionFile = (index) => {
    const updatedFiles = [...prescriptionFiles];
    updatedFiles.splice(index, 1);
    setPrescriptionFiles(updatedFiles);
  };

  const handleBookingSubmit = () => {
    setValidationErrors({});
    const missingFields = [];

    const confirmation = window.confirm(
      "Did you receive the payment and want to submit?"
    );

    if (confirmation) {
      if (!items.length) {
        missingFields.push("Items");
      }

      if (missingFields.length > 0) {
        const errorMessage = `required fields:${missingFields.join(", ")}`;
        toast.error(errorMessage);
        return;
      }

      if (!partnerName && !partner_name) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          partnerNameError: "partner Name is required.",
        }));
        missingFields.push("Partner Name");
        return;
      }

      const filteredItems = items.filter(item => item.item_price !== '');

      const bookingData = {
        Items: filteredItems,
        booking_date,
        booking_id,
        city,
        discount_price: tatkalCaresDiscount,
        doctor_name : doctorData?.DoctorName || bookingDetail?.doctor_name,
        doctor_number : doctorData?.DoctorMobileNo || bookingDetail?.doctor_number,
        final_amount: totalAmount - tatkalCaresDiscount,
        partner_name: partnerName ? partnerName : partner_name ,
        partner_number: partnerMobile ? partnerMobile : partner_number,
        patient_name,
        patient_number,
        prescription,
        remarks,
        report,
        status: "In Progress",
        payment_status: "Paid",
      };

      console.log(id, bookingData, prescriptionFiles, "BDD");

      dispatch(
        updateBookingAsyncOpenBooking({ id, bookingData, prescriptionFiles })
      );
      toast.success(message);
      navigate("/bookingsHistory");
    } else {
    }
  };

  const handleAddRow = () => {
    setItems([
      ...items,
      { item_name: "", item_price: "", item_discount: "", itemId: "" },
    ]);
  };

  const handleRemoveRow = (index, itemId) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    dispatch(removeBookingItemAsync({ id, itemId }));
  };

  const handleChange = (index, field, value) => {
    const updatedItems = [...items];
    const selectedMasterItem = Array.isArray(itemMaster) &&
      itemMaster.length > 0 &&
      itemMaster.find((item) => item._id === value);
  
    if (selectedMasterItem) {
      updatedItems[index].itemId = selectedMasterItem._id;
      updatedItems[index].item_price = selectedMasterItem.price;
      updatedItems[index].item_name = selectedMasterItem.itemName;
      updatedItems[index].item_discount = selectedMasterItem.tc_margin_category?.tc_discount || 0;
      
      const addbookingitem = {
        itemId: selectedMasterItem._id,
        item_name: selectedMasterItem.itemName,
        item_price: selectedMasterItem.price,
        item_discount: selectedMasterItem.tc_margin_category?.tc_discount || 0,
      };
      console.log(addbookingitem, 'SIM');
  
      setItems(updatedItems);
      dispatch(addBookingItemAsync({ id, addbookingitem }));
    } else {
      // Handle the case when selectedMasterItem is not found
    }
  };
  

  const handleDownloadPrescription = (prescriptionUrl) => {
    const link = document.createElement("a");
    link.href = prescriptionUrl;
    link.target = "_blank";
    link.click();
  };

  return (
    // page body part start //
    <div className="mb30">
      <Grid className="mb10" container spacing={3}>
        {/* Patient Information part start */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <AccountCircleOutlinedIcon /> Patient Information
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Booking ID</b>
                        </TableCell>
                        <TableCell>{booking_id}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Patient Name</b>
                        </TableCell>
                        <TableCell>{patient_name}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Patient Mobile No</b>
                        </TableCell>
                        <TableCell>{patient_number}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Status</b>
                        </TableCell>
                        <TableCell>{status}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Prescription</b>
                        </TableCell>
                        <TableCell>
                          {prescription?.length === 1 ? (
                            <IconButton
                              color="success"
                              variant="outlined"
                              size="small"
                              onClick={() =>
                                handleDownloadPrescription(prescription[0])
                              }
                              target="_blank"
                              download
                            >
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          ) : prescription?.length === 0 ? (
                            <TableCell>
                              <input
                                type="file"
                                id="Prescription-upload-input"
                                style={{ display: "none" }}
                                multiple
                                onChange={handlePrescriptionFileChange}
                                required
                              />
                              <label htmlFor="Prescription-upload-input">
                                <Button
                                  component="span"
                                  endIcon={<CloudUploadOutlinedIcon />}
                                  style={{
                                    background: red ? "red" : "",
                                    color: red ? "white" : "",
                                  }}
                                >
                                  Upload
                                </Button>
                              </label>
                            </TableCell>
                          ) : (
                            <Select
                              defaultValue=""
                              onChange={(e) =>
                                handleDownloadPrescription(e.target.value)
                              }
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                Select
                              </MenuItem>
                              {Array.isArray(prescription) &&
                                prescription.map((prescriptionUrl, index) => (
                                  <MenuItem key={index} value={prescriptionUrl}>
                                    Prescription {index + 1}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        </TableCell>
                      </TableRow>
                      {prescription?.length == 0 && (
                        <TableRow>
                          <TableCell>Show Prescription</TableCell>
                          <TableCell
                            style={{
                              border: "1px solid #ccc",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {/* Render selected prescription files with remove buttons */}
                              {prescriptionFiles.map((file, index) => (
                                <div
                                  key={index}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <span>{file.name}</span>
                                  <Tooltip title="Delete" placement="right">
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() =>
                                        handleRemovePrescriptionFile(index)
                                      }
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Patient Information part end */}

        {/* Partner Information part start */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <SupervisedUserCircleOutlinedIcon /> Partner Information
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {partner_number ? (
                        <>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Partner Name</b>
                            </TableCell>
                            <TableCell>{partner_name}</TableCell>
                          </TableRow>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Partner Mobile No</b>
                            </TableCell>
                            <TableCell>
                              {maskPhoneNumber(partner_number)}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Search Partner</b>
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 120 }}
                              >
                                <InputLabel id="demo-simple-select-standard-label">
                                  Partner
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={allPartner}
                                  onChange={handlePartnerChange}
                                  label="Partner"
                                >
                                  {patnerList?.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                      {" "}
                                      {item.name}{" "}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>

                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Partner Name</b>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="outlined-read-only-input"
                                name="partnerName"
                                value={partnerName}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                                error={!!validationErrors.partnerNameError}
                                helperText={validationErrors.partnerNameError}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Partner Mobile No</b>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="partnerMobileNo"
                                value={partnerMobile}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                                disabled
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>OPD Appointment Date</b>
                        </TableCell>
                        <TableCell>
                          {booking_date
                            ? new Date(booking_date).toLocaleDateString("en-GB")
                            : "-"}
                        </TableCell>
                      </TableRow>
                      {doctor_name ? (
                        <>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Doctor's Name</b>
                            </TableCell>
                            <TableCell>{doctor_name}</TableCell>
                          </TableRow>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Doctor's Number</b>
                            </TableCell>
                            <TableCell>{doctor_number}</TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Search Doctor</b>
                            </TableCell>
                            <TableCell>
                              <Autocomplete
                                options={suggestedDoctor}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) =>
                                  handleSelectDoctor(value)
                                }
                                onInputChange={handleInputChangeDoctor}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search Doctor"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Doctor's Name</b>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="DoctorName"
                                value={doctorData.DoctorName}
                                onChange={(e) =>
                                  setDoctorData({
                                    ...doctorData,
                                    DoctorName: e.target.value,
                                  })
                                }
                                variant="outlined"
                                size="small"
                                disabled
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Doctor's Number</b>
                            </TableCell>
                            <TableCell>
                              <TextField
                                name="DoctorMobileNo"
                                value={doctorData.DoctorMobileNo}
                                onChange={(e) =>
                                  setDoctorData({
                                    ...doctorData,
                                    DoctorMobileNo: e.target.value,
                                  })
                                }
                                variant="outlined"
                                size="small"
                                disabled
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Partner Information part end */}

        {/* Add Test part start */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <ContentPasteGoOutlinedIcon /> Add Test
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>Test Name</TableCell>
                        <TableCell>Test Amount</TableCell>
                        <TableCell>TatkalCares Discount</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            endIcon={<PostAddOutlinedIcon />}
                            style={{ marginLeft: "15px", marginBottom: "10px" }}
                            onClick={handleAddRow}
                          >
                            {" "}
                            Add
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(items) &&
                        items.map((element, index) => (
                          <TableRow
                            key={index}
                            hover
                            sx={{ "td,th": { border: 1 } }}
                          >
                            <TableCell width={500}>
                              <FormControl fullWidth>
                                <Autocomplete
                                  id={`item_name-${index}`}
                                  options={
                                    Array.isArray(itemMaster) ? itemMaster : []
                                  }
                                  getOptionLabel={(option) => option.itemName}
                                  value={
                                    Array.isArray(itemMaster)
                                      ? itemMaster.find(
                                          (item) => item._id === element.itemId
                                        ) || null
                                      : null
                                  }
                                  onChange={(e, value) =>
                                    handleChange(
                                      index,
                                      "item_name",
                                      value ? value._id : ""
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={element.item_name}
                                      variant="outlined"
                                    />
                                  )}
                                  disableClearable
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell width={150}>
                              <h2>{element.item_price}</h2>
                            </TableCell>
                            <TableCell width={150}>
                              <h2>{element.item_discount}%</h2>
                            </TableCell>
                            <TableCell>
                              <Button
                                color="error"
                                variant="contained"
                                endIcon={<BookmarkRemoveOutlinedIcon />}
                                onClick={() =>
                                  handleRemoveRow(index, element.itemId)
                                }
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Add Test part end */}

        {/* tac part start here */}
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Total Amount</b>
                      </TableCell>
                      <TableCell>{totalAmount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Tc Discount</b>
                      </TableCell>
                      <TableCell>{tatkalCaresDiscount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Final Payment</b>
                      </TableCell>
                      <TableCell>
                        {totalAmount.toFixed(2) -
                          tatkalCaresDiscount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                    <TableCell>
                        <b>Payment Status :</b>
                      </TableCell>
                      <TableCell>
                        <b>Paid</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div style={{ display: "flex" }}>
                  <>
                    <Button
                      className="mt20"
                      variant="contained"
                      onClick={handleBookingSubmit}
                      endIcon={<SendIcon />}
                      style={{ fontSize: "0.8rem", padding: "8px 16px" }} // Adjust font size and padding
                    >
                      Submit
                    </Button>
                    {status !== "Cancelled" && (
                      <>
                        {/* Add some space */}
                        <Button
                          onClick={handleCancel}
                          className="mt20"
                          // variant="contained"
                          endIcon={<CancelScheduleSendIcon />}
                          style={{
                            marginLeft: "10px", // Adjust margin-left for spacing
                            fontSize: "0.8rem", // Adjust font size
                            padding: "8px 16px", // Adjust padding
                            background: "red",
                            color: "white",
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </>
                </div>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        {/* tac part end here */}
      </Grid>
    </div>
    // page body part end //
  );
};
export default OpenBookingDetails;
