import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { SideBarData } from "./sideBarData";
import weblogo from "../images/web-logo.png";

import { useSelector } from "react-redux";
import { loginAsync } from "../Store/Features/AuthSlice";

const drawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = ({ open, setOpen }) => {
  const { token, user, isHospital, isDiagnostics } = useSelector(
    (state) => state.auth
  );

  console.log(isHospital, "hospital");
  console.log(isDiagnostics, "diagno");
  const myhospital = localStorage.getItem("isOnlyHospital") === "true";
  console.log(myhospital, "myhospital");

  const theme = useTheme();
  const navigate = useNavigate();
  const loaction = useLocation();

  const [selectedItem, setSelectedItem] = useState(null);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const filteredSideBarData = SideBarData.filter((val) => {
    if (isHospital && isDiagnostics && (val.type === 0 || val.type === 1)) {
      return true;
    }
    if (!isHospital && isDiagnostics && val.type === 0) {
      return true;
    }
    if (myhospital && val.type === 2) {
      return true;
    }
    return false;
  });
  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div className="width100" align="center">
            <img src={weblogo} width="150" alt="" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {filteredSideBarData.map((val) => (
          <MenuList className="sidebarManuUl" key={val.key}>
            <MenuItem
              onClick={() => {
                if (val?.key === "logout") {
                  //TODO
                } else {
                  navigate(val?.key);
                  setSelectedItem(val?.key); // Update selected item
                }
              }}
              style={{
                backgroundColor:
                  val.title === "Diagnostics" || val.title === "Hospital"
                    ? "#00c2c1"
                    : loaction.pathname === val.key
                    ? "#068382" // Red for selected item
                    : "",
                color:
                  val.title === "Diagnostics" || val.title === "Hospital"
                    ? "#fff"
                    : loaction.pathname === val.key
                    ? "#fff" // Adjust text color for selected item
                    : "",
              }}
            >
              <ListItemIcon
                style={{ width: 50, padding: "2px", paddingBottom: "0px" }}
              >
                {val.icon}
              </ListItemIcon>
              <ListItemText>{val.title}</ListItemText>
            </MenuItem>
          </MenuList>
        ))}
      </Drawer>
    </>
  );
};

export default SideBar;
