import React from "react";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import StyleIcon from '@mui/icons-material/Style';
import BedIcon from '@mui/icons-material/Bed';
import VaccinesIcon from '@mui/icons-material/Vaccines';

export const SideBarData = [
    {
        title: "Diagnostics",
        icon: <HomeIcon />,
        key: "/",
        type:0
    },
    {
        title: "Open Bookings",
        icon: <CalendarMonthOutlinedIcon />,
        key: "/openBookings",
        type:0
    },
    {
        title: "Bookings History",
        icon: <BookOutlinedIcon />,
        key: "/bookingsHistory",
        type:0
    },
    // {
    //     title: "Bookings Details",
    //     icon: <CalendarMonthOutlinedIcon />,
    //     key: "/openbookingdetails",
    // },
    {
        title: "TC Analytics",
        icon: <TroubleshootOutlinedIcon />,
        key: "/analytics",
        type:0
    },
    {
        title: "Payment Settlement",
        icon: <StyleIcon />,
        key: "/paymentSettlement",
        type:0
    },
    {
        title: "Test Information",
        icon: <VaccinesIcon />,
        key: "/testsDetails",
        type:0
    },
    {
        title: "Categories",
        icon: <CategoryIcon />,
        key: "/categoryDetails",
        type:0
    },
    {
        title: "Hospital",
        icon: <HomeIcon />,
        key: "/hospital_dashboard",
        type:1
    },
    {
        title: "Open Bookings",
        icon: <CalendarMonthOutlinedIcon />,
        key: "/hospital-booking",
        type:1
    },
    {
        title: "Bookings History",
        icon: <BookOutlinedIcon />,
        key: "/hospital-bookinghistory",
        type:1
    },
    // {
    //     title: "Bookings Details",
    //     icon: <CalendarMonthOutlinedIcon />,
    //     key: "/openbookingdetails",
    // },
    {
        title: "TC Analytics",
        icon: <TroubleshootOutlinedIcon />,
        key: "/hospital-analytics",
        type:1
    },
    {
        title: "Payment Settlement",
        icon: <StyleIcon />,
        key: "/hospital-settlement",
        type:1
    },
    {
        title: "Bed Information",
        icon: <BedIcon />,
        key: "/hospital-Item",
        type:1
    },
    {
        title: "Categories",
        icon: <CategoryIcon />,
        key: "/hospital-category",
        type:1
    },
    {
        title: "TC Partner",
        icon: <HandshakeOutlinedIcon />,
        key: "/partner",
        type: 0
    },

    {
        title: "Dashboard",
        icon: <HomeIcon />,
        key: "/hospital-dashboard",
        type:2
    },

    {
        title: "Open Bookings",
        icon: <CalendarMonthOutlinedIcon />,
        key: "/onlyHospital-openbooking",
        type:2
    },

    {
        title: "Booking History",
        icon: <BookOutlinedIcon />,
        key: "/onlyHospital-bookingHistory",
        type:2
    },

    {
        title: "TC Analytics",
        icon: <TroubleshootOutlinedIcon />,
        key: "/onlyHospital-analytics",
        type:2
    },
    {
        title: "Payment Settlement",
        icon: <StyleIcon />,
        key: "/onlyHospital-settlement",
        type:2
    },

    {
        title: "Categories",
        icon: <CategoryIcon />,
        key: "/onlyHospital-category",
        type:2
    },
    {
        title: "Bed Information",
        icon: <BedIcon />,
        key: "/onlyHospital-Item",
        type:2
    },
    {
        title: "TC Partner",
        icon: <HandshakeOutlinedIcon />,
        key: "/onlyHospital-partners",
        type:2
    },
]