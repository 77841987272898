import { configureStore } from "@reduxjs/toolkit";


import authReducer from "./Features/AuthSlice";
import bookingReducer from "./Features/BookingSlice";
import iMlReducer from './Features/IMLSlice';
import iMlcateListReducer from './Features/IMlCateListSlice';
import tcpartmerReducer from './Features/TcPartnerSlice'
import TcpatientReducer from "./Features/TcPatientSlice";
import TcDoctorReducer from "./Features/TcDoctorSlice";
import TctransactionReducer from "./Features/Tctransaction";
import bulkItemUploadReducer from './Features/BulkUpload'


import hosItemReducer from './Features/OnlyHospital/hosItemCategory'
import hosItemListRedducer from './Features/OnlyHospital/HospitalItem'
import HospitalBookingReducer from './Features/OnlyHospital/HospitalBooking'
import hospitalPartnerReducer from './Features/OnlyHospital/HosPartner'
import hospitalDoctorReducer from './Features/OnlyHospital/HospitalDoctor'
import hospitalTransationReducer from './Features/OnlyHospital/HospitalTransation'
import HosPatientReducer from './Features/OnlyHospital/HosPatient'
export const store = configureStore({
  reducer: {
    imlList: iMlReducer,
    auth: authReducer,
    booking: bookingReducer,
    imlcateList: iMlcateListReducer,
    tcPartner: tcpartmerReducer,
    tcPatient: TcpatientReducer,
    tcDoctor: TcDoctorReducer,
    tctransaction:TctransactionReducer,
    bulkuploads: bulkItemUploadReducer,
    hospitalCategoryList: hosItemReducer,
    hospitalItemList: hosItemListRedducer,
    hospitalBookingList: HospitalBookingReducer,
    hospitalPartnerList: hospitalPartnerReducer,
    hospitalDocList: hospitalDoctorReducer,
    hosTransation: hospitalTransationReducer,
    PatientList: HosPatientReducer
  },
});
