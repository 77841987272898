import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";

const token = localStorage.getItem("token");

// Async thunk for fetching doctor list
export const fetchDoctorsListAsync = createAsyncThunk("tcDoctor/fetchList", async (searchValue) => {
  try {
    console.log(searchValue, 'sv');

    // Initialize request body with empty name and mobile
    let requestBody = {
      name: '',
      mobile: ''
    };

    // Check if searchValue is a valid mobile number
    if (isMobileNumber(searchValue)) {
      requestBody.mobile = searchValue;
    } else {
      requestBody.name = searchValue;
    }
    const response = await axios.post(
      `${Base_url}/api/doctors/list`,
      requestBody,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
  } catch (error) {
    // Handle the error or return rejectWithValue(error.response.data);
  }
});

// Function to check if the searchValue is a valid mobile number
function isMobileNumber(value) {
  // Check if value is a string consisting of digits
  return typeof value === 'string' && /^\d+$/.test(value);
}

export const createDoctorBookingAsync = createAsyncThunk("tcDoctor/createBooking", async (doctorData) => {
  try {
    const response = await axios.post(
      `${Base_url}/api/doctors/booking/create`,
      {
        "name": doctorData.DoctorName,
        "mobile": doctorData.DoctorMobileNo
      },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
  } catch (error) {
    throw error; // Rethrow the error to be handled by the rejection case
  }
});

// Doctor slice
const TcDoctorSlice = createSlice({
  name: 'tcDoctor',
  initialState: {
    DoctorList: [],
    DoctorDetails: {},
    status: 'idle',
    error: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorsListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDoctorsListAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.DoctorList = action.payload;
      })
      .addCase(fetchDoctorsListAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message || 'An error occurred';
      })
      .addCase(createDoctorBookingAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDoctorBookingAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createDoctorBookingAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'An error occurred';
      });
  },
});



// Export the reducer
export default TcDoctorSlice.reducer;
