import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Base_url from "../../Base_url";
import toast from "react-hot-toast";

const token = localStorage.getItem("token");

export const createBookingAsync = createAsyncThunk(
  "booking/create",
  async (
    { bookingData, prescriptionFiles, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { Items, ...restBookingData } = bookingData;
      const formData = new FormData();

      // Append prescription files
      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("prescription", prescriptionFiles[i]);
      }

      // Append items data
      for (let i = 0; i < Items.length; i++) {
        formData.append(`Items[${i}][item_name]`, Items[i].item_name);
        formData.append(`Items[${i}][item_price]`, Items[i].item_price);
        formData.append(`Items[${i}][item_discount]`, Items[i].item_discount);
        formData.append(`Items[${i}][itemId]`, Items[i].itemId);
      }

      // Append other booking data
      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/booking/create`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(
        updateBookingStatusAsync({
          selecetedId: response?.data?.data?._id,
          status: "Paid",
          remarks: "",
        })
      );
      dispatch(fetchBookingsListAsync({ status: "In Progress" }));
      // toast.success(response.data?.message);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createHosBookingAsync = createAsyncThunk(
  "bookinghos/create",
  async (
    { bookingData, prescriptionFiles, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { Items, ...restBookingData } = bookingData;
      const formData = new FormData();

      // Append prescription files
      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("prescription", prescriptionFiles[i]);
      }
      formData.append("type", type);
      // Append items data
      Items.forEach((item, index) => {
        formData.append(`Items[${index}][total_beds]`, item.total_beds);
        formData.append(`Items[${index}][item_name]`, item.item_name);
        formData.append(
          `Items[${index}][per_day_charges]`,
          item.per_day_charges
        );
        formData.append(`Items[${index}][item_discount]`, item.item_discount);
        formData.append(
          `Items[${index}][hospital_item_id]`,
          item.hospital_item_id
        );
      });

      // Append other booking data
      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/hospital-booking/create`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (type) {
        dispatch(
          updateBookingStatusAsync({
            selecetedId: response?.data?.data?._id,
            status: "Paid",
            remarks: "",
            type: 1,
          })
        );
      } else {
        dispatch(
          updateBookingStatusAsync({
            selecetedId: response?.data?.data?._id,
            status: "Paid",
            remarks: "",
          })
        );
      }
      dispatch(fetchBookingsListAsync({ status: "In Progress" }));
      toast.success(response.data?.message);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBookingsListAsync = createAsyncThunk(
  "booking/fetchList",
  async (dataToSend) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/booking/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response?.data;
    } catch (error) {
      // return rejectWithValue(error.response.data);
    }
  }
);

export const updateBookingAsync = createAsyncThunk(
  "booking/update",
  async (
    { id, bookingData, type, prescriptionFiles },
    { rejectWithValue, dispatch }
  ) => {
    try {
      // Extracting Items from bookingData
      const { Items, ...restBookingData } = bookingData;

      // Creating FormData
      const formData = new FormData();

      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("report", prescriptionFiles[i]);
      }

      if (type) {
        formData.append("type", type);

        Items.forEach((item, index) => {
          formData.append(`Items[${index}][total_beds]`, item.total_beds);
          formData.append(`Items[${index}][item_name]`, item.item_name);
          formData.append(
            `Items[${index}][per_day_charges]`,
            item.per_day_charges
          );
          formData.append(`Items[${index}][item_discount]`, item.item_discount);
          formData.append(
            `Items[${index}][hospital_item_id]`,
            item.hospital_item_id
          );
        });
      } else {
        for (let i = 0; i < Items.length; i++) {
          formData.append(`Items[${i}][item_name]`, Items[i].item_name);
          formData.append(`Items[${i}][item_price]`, Items[i].item_price);
          formData.append(`Items[${i}][item_discount]`, Items[i].item_discount);
          formData.append(`Items[${i}][itemId]`, Items[i].itemId);
        }
      }

      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/booking/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { message } = response?.data;
      // dispatch(
      //   updateBookingStatusAsync({
      //     selecetedId: response?.data?.data?._id,
      //     status: "Paid",
      //     remarks: "",

      //   })
      // );
      if (type) {
        const dataToSend = {
          selectPatientno: "",
          search: "",
          selectPatnerno: "",
          selectbookingId: "",
          status: "In Progress",
          type: 1,
        };
        dispatch(fetchBookingsListAsync(dataToSend));
      } else {
        const dataToSend = {
          selectPatientno: "",
          search: "",
          selectPatnerno: "",
          selectbookingId: "",
          status: "In Progress",
        };
        dispatch(fetchBookingsListAsync(dataToSend));
      }
      toast.success(message);
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response?.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const updateBookingAsyncOpenBooking = createAsyncThunk(
  "booking/updateopenBooking",
  async (
    { id, bookingData, prescriptionFiles, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { Items, ...restBookingData } = bookingData;
      const formData = new FormData();

      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("prescription", prescriptionFiles[i]);
      }

      if (type) {
        formData.append("type", type);

        Items.forEach((item, index) => {
          formData.append(`Items[${index}][total_beds]`, item.total_beds);
          formData.append(`Items[${index}][item_name]`, item.item_name);
          formData.append(
            `Items[${index}][per_day_charges]`,
            item.per_day_charges
          );
          formData.append(`Items[${index}][item_discount]`, item.item_discount);
          formData.append(
            `Items[${index}][hospital_item_id]`,
            item.hospital_item_id
          );
        });
      } else {
        for (let i = 0; i < Items.length; i++) {
          formData.append(`Items[${i}][item_name]`, Items[i].item_name);
          formData.append(`Items[${i}][item_price]`, Items[i].item_price);
          formData.append(`Items[${i}][item_discount]`, Items[i].item_discount);
          formData.append(`Items[${i}][itemId]`, Items[i].itemId);
        }
      }

      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/booking/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { message } = response?.data;

      if (type) {
        const dataToSend = {
          selecetedId: response?.data?.data?._id,
          status: "Paid",
          remarks: "",
          type: 1,
        };
        dispatch(updateBookingStatusAsync(dataToSend));
        dispatch(fetchBookingsListAsync({ status: "In Progress", type: 1 }));
      } else {
        const dataToSend = {
          selecetedId: response?.data?.data?._id,
          status: "Paid",
          remarks: "",
        };
        dispatch(updateBookingStatusAsync(dataToSend));
        dispatch(fetchBookingsListAsync({ status: "In Progress" }));
      }

      // dispatch(
      //   updateBookingStatusAsync({
      //     selecetedId: response?.data?.data?._id,
      //     status: "Paid",
      //     remarks: "",

      //   })
      // );
      //   dispatch(
      //     fetchBookingsListAsync({
      //       selectPatientno: "",
      //       search: "",
      //       selectPatnerno: "",
      //       selectbookingId: "",
      //       status1: "Pending",
      //     })
      //   );
      toast.success(message);
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response?.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

// export const fetchBookingsListAsync1 = createAsyncThunk(
//   "booking/fetchList1",
//   async ({ selectPatientno, search, selectPatnerno, selectbookingId,start ,end ,status1}) => {
//     try {
//       const response = await axios.post(
//         `${Base_url}/api/booking/list`,
//         {
//           patient_number: selectPatientno,
//           partner_number: selectPatnerno,
//           keyword_search: search,
//           booking_id: selectbookingId,
//           start_date:start,
//           end_date:end,
//           status:status1,

//         },
//         {
//           headers: { Authorization: `${token}` },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       // return rejectWithValue(error.response.data);
//     }
//   }
// );

export const fetchBookingDetailsAsync = createAsyncThunk(
  "booking/fetchDetails",
  async (dataToSend, { rejectWithValue }) => {
    try {
      const { id, type } = dataToSend;
      let url = `${Base_url}/api/booking/details/${id}`;

      // Check if type is present and equals 1
      if (type && type === 1) {
        url += `?type=1`; // Append type=1 to the URL
      }

      const response = await axios.get(url, {
        headers: { Authorization: `${token}` },
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeBookingItemAsync = createAsyncThunk(
  "booking/removeItem",
  async ({ id, itemId, type }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/booking/item/remove/${id}`,
        { itemId: itemId, type: type || "" },
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { message } = response?.data;
      toast.success(message || "removed success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response?.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const addBookingItemAsync = createAsyncThunk(
  "booking/addItem",
  async ({ id, addbookingitem }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/booking/item/add/${id}`,
        { ...addbookingitem },
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { message } = response?.data;
      toast.success(message || "booking create success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage || "faild to create booking");
        return rejectWithValue(error.response.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const updateBookingStatusAsync = createAsyncThunk(
  "booking/updateStatus",
  async (
    { selecetedId, status, remarks, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/booking/status/update/${selecetedId}`,
        { payment_status: status, remarks: remarks, type: type },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { message } = response?.data;
      // toast.success(message || "Update success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          // error.response.data?.message || "An error occurred";
          toast.error(errorMessage || "failed");
        return rejectWithValue(error.response.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const deleteStatusAsync = createAsyncThunk(
  "booking/deleteStatus",
  async ({ selecetedId, status, type }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/booking/status/update/${selecetedId}`,
        { status: status, type: type },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // dispatch(
      //   fetchBookingsListAsync({
      //     selectPatientno: "",
      //     search: "",
      //     selectPatnerno: "",
      //     selectbookingId: "",
      //   })
      // );
      const { message } = response?.data;
      toast.success(message || "delete success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage || "somtjing error to delete");
        return rejectWithValue(error.response.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

// Redux slice for booking-related actions
const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    bookingsList: [],
    bookingDetails: {},
    status: "idle",
    error: "",
    message: "",
    totalCount: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBookingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBookingAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(createBookingAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
        state.message = action.payload?.message;
      })

      .addCase(createHosBookingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createHosBookingAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(createHosBookingAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
        state.message = action.payload?.message;
      })

      .addCase(updateBookingAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBookingAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingAsyncOpenBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBookingAsyncOpenBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingAsyncOpenBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
        state.message = action.payload?.message;
      })
      .addCase(fetchBookingsListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBookingsListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bookingsList = action.payload?.data;
        state.totalCount = action.payload;
      })
      .addCase(fetchBookingsListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.bookingsList = action.payload.data;
        state.error = action.payload?.message || "An error occurred";
      })
      .addCase(fetchBookingDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBookingDetailsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bookingDetails = action.payload;
      })
      .addCase(fetchBookingDetailsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
        state.bookingDetails = action.payload;
      })
      .addCase(removeBookingItemAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeBookingItemAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removeBookingItemAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })
      .addCase(addBookingItemAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addBookingItemAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addBookingItemAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })
      .addCase(updateBookingStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBookingStatusAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingStatusAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      .addCase(deleteStatusAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteStatusAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(deleteStatusAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      });
  },
});

export default bookingSlice.reducer;
