import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchIMLCateAsync } from "../Store/Features/IMlCateListSlice";
import {
  createIMLListAsync,
  fetchIMLListAsync,
} from "../Store/Features/IMLSlice";
import { useNavigate } from "react-router-dom";
import { HospitalCategory } from "../Store/Features/OnlyHospital/hosItemCategory";
import { AddHospitalItems } from "../Store/Features/OnlyHospital/HospitalItem";

const OnlyHosAddBed = () => {
  const [inputData, setInputData] = useState({
    item_name:'',
    total_beds: '',
    HospitalTCMarginCategory: '',
    per_day_charges: '',    
    type:1,
    tc_margin_commission_percentage:'',
  });

  const [errors, setErrors] = useState({
    item_name:'',
    total_beds: false,
    HospitalTCMarginCategory: false,
    per_day_charges: false,
    tc_margin_commission_percentage:''
  });

  const navigate = useNavigate();
  const imlCateList = useSelector((state) => state.hospitalItemList.hospitalItem);
  const dispatch = useDispatch();

//   console.log(imlCateList,'cl');

const handleInputChange = (event) => {
  const { name, value } = event.target;

  // Convert tc_margin_commission_percentage to a number
  const parsedValue = name === 'tc_margin_commission_percentage' ? Number(value) : value;

  setInputData((prevData) => ({
    ...prevData,
    [name]: parsedValue,
  }));

  // Clear error for the field when user starts typing
  setErrors((prevErrors) => ({
    ...prevErrors,
    [name]: false,
  }));
};

  const handleCategoryChange = (event) => {
    setInputData((prevData) => ({
      ...prevData,
      HospitalTCMarginCategory: event.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      HospitalTCMarginCategory: false,
    }));
  };

  const handleSubmit = () => {
    // Check for any empty fields
    const newErrors = {};
    let hasErrors = false;
    Object.keys(inputData).forEach((key) => {
      if (!inputData[key]) {
        newErrors[key] = true;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrors(newErrors);
    } else {
        const dataToDispatch = {
            ...inputData,
            total_beds: parseInt(inputData.total_beds),
            // available_beds: parseInt(inputData.available_beds),
            per_day_charges: parseInt(inputData.per_day_charges),
          };
          dispatch(AddHospitalItems(dataToDispatch));
    navigate('/onlyHospital-Item');
    }
  };

  useEffect(() => {
    dispatch(HospitalCategory())
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <h2 className="m0 mb10 iconTitel">
            <ContentPasteGoOutlinedIcon /> Add Bed
          </h2>
          <Paper className="customTable" sx={{ width: '100%' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow hover >
                  <TableCell width={90}>Item Name</TableCell>
                  {/* <TableCell width={90}>Available Bed</TableCell> */}
                    <TableCell width={90}>Total Bed</TableCell>
                    <TableCell width={80}>Category</TableCell>
                    <TableCell width={90}>Price</TableCell>
                    <TableCell width={90}>TC Margin</TableCell>
                    <TableCell width={50}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover sx={{ 'td,th': { border: 1 } }}>
                  <TableCell>
                      <TextField
                        label="Item Name"
                        name="item_name"
                        value={inputData.item_name}
                        onChange={handleInputChange}
                        error={errors.item_name}
                        helperText={errors.item_name ? 'This field is required' : ''}
                      />
                    </TableCell>

                    {/* <TableCell>
                      <TextField
                        label="Available beds"
                        name="available_beds"
                        value={inputData.available_beds}
                        onChange={handleInputChange}
                        error={errors.available_beds}
                        helperText={errors.available_beds ? 'This field is required' : ''}
                      />
                    </TableCell> */}

                    <TableCell>
                      <TextField
                        label="Total Bed"
                        name="total_beds"
                        value={inputData.total_beds}
                        onChange={handleInputChange}
                        error={errors.total_beds}
                        helperText={errors.total_beds ? 'This field is required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Bed </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Dr. Name"
                          value={inputData.HospitalTCMarginCategory}
                          onChange={handleCategoryChange}
                          error={errors.HospitalTCMarginCategory}
                        >
                          {Array.isArray(imlCateList) &&
                            imlCateList.map((item) => (
                              <MenuItem value={item._id} key={item._id}>
                                {item.group_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Price"
                        name="per_day_charges"
                        value={inputData.per_day_charges}
                        onChange={handleInputChange}
                        error={errors.per_day_charges}
                        helperText={errors.per_day_charges ? 'This field is required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="TC Margin"
                        name="tc_margin_commission_percentage"
                        value={inputData.tc_margin_commission_percentage}
                        onChange={handleInputChange}
                        error={errors.tc_margin_commission_percentage}
                        helperText={errors.tc_margin_commission_percentage ? 'This field is required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        endIcon={<PostAddOutlinedIcon />}
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OnlyHosAddBed;