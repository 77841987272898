import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../../Base_url";
import axios from "axios";

const token = localStorage.getItem("token");

export const fetchPatientsList = createAsyncThunk(
  "Patient/patientList",
  async (searchValue) => {
    try {
      // Initialize request body with empty name and mobile
      let requestBody = {
        name: "",
        mobile: "",
      };

      // Check if searchValue is a valid mobile number
      if (isMobileNumber(searchValue)) {
        requestBody.mobile = searchValue;
      } else {
        requestBody.name = searchValue;
      }

      const response = await axios.post(
        `${Base_url}/api/users/getAll`,
        requestBody,
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error; // Rethrow the error to be caught by the caller
    }
  }
);

// Function to check if the searchValue is a valid mobile number
function isMobileNumber(value) {
  // Check if value is a string consisting of digits
  return typeof value === "string" && /^\d+$/.test(value);
}

export const createPatientBooking = createAsyncThunk(
  "Patients/createPatient",
  async (patientData) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/users/hospital/create`,
        {
          name: patientData.patientName,
          mobile: patientData.patientMobileNo,
          // Add other necessary properties
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const HosPatient = createSlice({
  name: "PatientList",
  initialState: {
    PatientLists: [],
    PatientDetails: {},
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientsList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPatientsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.PatientLists = action.payload;
      })
      .addCase(fetchPatientsList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })
      .addCase(createPatientBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPatientBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createPatientBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "An error occurred";
      });
  },
});

export default HosPatient.reducer;
