import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HospitalCategory } from "../Store/Features/OnlyHospital/hosItemCategory";

const OnlyHosCatagory = () => {
  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.hospitalCategoryList.hosCategory);

  const status = useSelector((state) => state.imlcateList.status);

  useEffect(() => {
    dispatch(HospitalCategory());
  }, [dispatch]);

 

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
          {/* header part work start */}
          <Grid className="mb10" container>
            {/* text head start */}
            <Grid item xs={4}>
              <h2 className="m0">Hospital Categories </h2>
            </Grid>

            {/* text head end */}

            {/* Radio box part start */}

            {/* search box end */}
          </Grid>
          {/* header part work end */}

          {/* table work start here */}
          <Paper className="customTable" sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 385 }}>
              <Table stickyHeader aria-label="sticky">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "red",
                      // borderBottom: "2px solid black",
                      "& th": {
                        fontSize: "0.875rem",
                        color: "#00c2c1",
                      },
                    }}
                  >
                    <TableCell>Category Name</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>TC Discount (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Display actual data when not loading */}
                  {status == "loading"
                    ? Array.isArray(categoryList) &&
                      categoryList?.map((item, index) => (
                        <TableRow key={index}>
                        <TableCell colSpan={3}>
                          <Skeleton animation="wave" height={30} variant="text" sx={{ fontSize: '1rem' }} />
                        </TableCell>
                      </TableRow>
                      ))
                    : Array.isArray(categoryList) &&
                      categoryList?.map((item, index) => (
                        <TableRow hover key={item._id}>
                          <TableCell>{item.group_name}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{item.discount_percentage}</TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* table work end here */}
        </CardContent>
      </Card>
      {/************ body area end here ************/}
    </>
  );
};

export default OnlyHosCatagory;
