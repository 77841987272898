import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Base_url from '../../Base_url';
import toast from 'react-hot-toast';

const token = localStorage.getItem("token");

export const loginAsync = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${Base_url}/api/partner/login`, credentials);
    const { token, data, message } = response.data;
    console.log(response.data?.data?.isHospital,'ishos');
    localStorage.setItem('token', token);
    localStorage.setItem('isHospital', response.data?.data?.isHospital);
    localStorage.setItem('isDiagnostics', response.data?.data?.isDiagnostics);
    localStorage.setItem('isOnlyHospital', response.data?.data?.isOnlyHospital);
    toast.success(message);
    return { token, user: data };
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message || 'An error occurred';
      toast.error(errorMessage);
      return rejectWithValue(error.response.data);
    } else {
      toast.error('An error occurred');
      return rejectWithValue(error.message);
    }
  }
});

export const getUserDetailsAsync = createAsyncThunk(
  'auth/getUserDetails',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { token } = getState().auth;

      // Retrieve isHospital, isDiagnostics, and isOnlyHospital values from local storage
      const isHospital = localStorage.getItem('isHospital') === 'true';
      const isDiagnostics = localStorage.getItem('isDiagnostics') === 'true';
      const isOnlyHospital = localStorage.getItem('isOnlyHospital') === 'true';

      let apiUrl;
      if (isOnlyHospital) {
        apiUrl = `${Base_url}/api/hospital/details`;
      } else if (isHospital && isDiagnostics) {
        apiUrl = `${Base_url}/api/partner/profile/details`;
      } else {
        apiUrl = `${Base_url}/api/partner/profile/details`;
      }

      const response = await axios.get(apiUrl, {
        headers: { Authorization: token },
      });

      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(logoutAsync());
      } else {
        const errorMessage =
          error.response?.data.message || 'An error occurred';
        toast.error(errorMessage);
        dispatch(logoutAsync());
        return rejectWithValue(error.response?.data || error.message);
      }
    }
  }
);





export const updateUserDetailsAsync = createAsyncThunk(
  "update/auth",
  async (dataToSend) => {
    try {
      const token = localStorage.getItem("token");
      const { id } = dataToSend;
      const response = await axios.post(
        `${Base_url}/api/partner/profile/update/${id}`,
        {
          ...dataToSend
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);

export const logoutAsync = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().auth;

      // Retrieve isHospital, isDiagnostics, and isOnlyHospital values from local storage
      const isHospital = localStorage.getItem('isHospital') === 'true';
      const isDiagnostics = localStorage.getItem('isDiagnostics') === 'true';
      const isOnlyHospital = localStorage.getItem('isOnlyHospital') === 'true';

      let logoutUrl;

      if (isOnlyHospital) {
        logoutUrl = `${Base_url}/api/hospital/logout`;
      } else if (isHospital && isDiagnostics) {
        logoutUrl = `${Base_url}/api/partner/logout`;
      } else {
        logoutUrl = `${Base_url}/api/partner/logout`;
      }

      localStorage.removeItem('token');

      await axios.get(logoutUrl, {
        headers: { Authorization: token },
      });

    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'An error occurred';
        toast.error(errorMessage);
        return rejectWithValue(error.response.data);
      } else {
        toast.error('An error occurred');
        return rejectWithValue(error.message);
      }
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') || '',
    user: {},
    status: 'idle',
    error: '',
    isLogedIn:false,
    isHospital:false,
    isDiagnostics:false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.isLogedIn = true
        
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message || 'An error occurred';
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isHospital = action.payload?.isHospital  ;
        state.isDiagnostics = action.payload?.isDiagnostics ;
        state.isLogedIn = true
      })
      .addCase(getUserDetailsAsync.rejected, (state, action) => {
        state.isLogedIn = false
        state.error = action.payload.message || 'An error occurred';
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.isLogedIn = false
        state.token = '';
        state.user = {};
      })
      .addCase(updateUserDetailsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserDetailsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(updateUserDetailsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message || "An error occurred";
      });
  },
});

export default authSlice.reducer;
