import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchIMLCateAsync } from "../Store/Features/IMlCateListSlice";
import {
  createIMLListAsync,
  detailsIMLListAsync,
  editIMLListAsync,
  fetchIMLListAsync,
} from "../Store/Features/IMLSlice";
import { useNavigate, useParams } from "react-router-dom";

const EditItemMaster = () => {
  const [inputData, setInputData] = useState({
    itemName: "",
    itemGroup: "",
    TCMarginCategory: "",
    price: "",
    tc_margin_commission_percentage :''
  });

  const navigate = useNavigate();

  const {id} = useParams()

  const imlCateList = useSelector((state) => state.imlcateList?.iMlCateValue);
  const iMllist = useSelector((state) => state.imlList?.itemMasterList);
  const iMlDetail = useSelector((state) => state.imlList?.itemMasterDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(testitem())
    const dataToSend = {
      id : id,
     }
    dispatch(fetchIMLCateAsync({}));
    dispatch(fetchIMLListAsync({}));
    dispatch(detailsIMLListAsync(dataToSend));
  }, [dispatch,id]);

  // console.log(imlCateList, "imlcate");
  // console.log(iMllist, "ilist");
  // console.log(iMlDetail, "I-detail");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    setInputData((prevData) => ({
      ...prevData,
      TCMarginCategory: event.target.value,
    }));
  };



  useEffect(() => {
    // Update inputData state when iMlDetail changes
    if (iMlDetail) {
      setInputData({
        itemName: iMlDetail.itemName,
        itemGroup: iMlDetail.itemGroup,
        TCMarginCategory: iMlDetail.TCMarginCategory,
        price: iMlDetail.price,
        tc_margin_commission_percentage : iMlDetail.tc_margin_commission_percentage
      });
    }
  }, [iMlDetail]);

  const handleSubmit = () => {
    // Handle submission logic here using inputData
    const dataToSend ={
      ...inputData,
      id: id,
    }

    dispatch(editIMLListAsync(dataToSend));
    navigate('/testsDetails')
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <h2 className="m0 mb10 iconTitel">
            <ContentPasteGoOutlinedIcon /> Edit Test
          </h2>
          <Paper className="customTable" sx={{ width: "100%" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow hover sx={{ "td,th": { border: 1 } }}>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Price (₹)</TableCell>
                    <TableCell>TC Margin (%)</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover sx={{ "td,th": { border: 1 } }}>
                    <TableCell>
                      <TextField
                        label="Test Name"
                        name="itemName"
                        value={inputData.itemName}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                        Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Dr. Name"
                          value={inputData.TCMarginCategory}
                          onChange={handleCategoryChange}
                        >
                          {imlCateList?.map((item) => (
                            <MenuItem value={item._id} key={item._id}>{item.group_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Group"
                        name="itemGroup"
                        value={inputData.itemGroup}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Price"
                        name="price"
                        value={inputData.price}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Tc Margin"
                        name="tc_margin_commission_percentage"
                        value={inputData.tc_margin_commission_percentage}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        endIcon={<PostAddOutlinedIcon />}
                        onClick={handleSubmit}
                      >
                        {" "}
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EditItemMaster;