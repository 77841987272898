import React, { useEffect, useState } from "react";
import "./login.css";
import SimpleImageSlider from "react-simple-image-slider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import daignotics from "../images/daignotics.jpg";
import daignotics1 from "../images/doctor_consultation_03.jpg";
import doctor from "../images/pngimg.com - doctor_PNG16023.png";
import user from "../images/user.jpg";
import { loginAsync } from "../Store/Features/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

const images = [
  { url: daignotics },
  { url: doctor },
  { url: daignotics1 },
  { url: user },
];

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, error , token, user, isHospital, isDiagnostics} = useSelector((state) => state.auth);
  const [credentials, setCredentials] = useState({ email: "", password: "" });


  console.log(isHospital, "hospital");
  console.log(isDiagnostics, "diagno");
  const myhospital = localStorage.getItem("isOnlyHospital") === "true";
  console.log(myhospital, "myhospital");

  const handleLogin = () => {
    dispatch(loginAsync(credentials));
    setTimeout(() => {
      window.location.reload();
  }, 2000);
  };

  useEffect(() => {
    if (token) {
      setCredentials({ email: "", password: "" }); // Clear credentials after successful login
      if (isHospital || isDiagnostics) {
        // If either isHospital or isDiagnostics is true, navigate to "/"
        navigate("/");
      } else if (myhospital) {
        // If myhospital is true and others are false, navigate to "/hospital-dashboard"
        navigate("/hospital-dashboard");
      } else {
        // Default navigation behavior, e.g., navigate to "/"
        navigate("/");
      }
    }
  }, [token, isHospital, isDiagnostics, myhospital, navigate]);

  return (
    <Grid
      xs={12}
      className="cards"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SimpleImageSlider
        width={300}
        height={300}
        images={images}
        autoPlay={true}
      />

      <Card className="LoginCard">
        <div className="mt20 mb10 ml12">
          <img
            src="http://tatkalcares.com/public/frontend/img/logo/logo.png"
            width="150"
            alt=""
          />
        </div>
        <h2 className="header-text">Welcome Back</h2>
        <p className="sub-header-text">Please login to your account</p>
        <div className="mt12">
          <TextField
            className="width92 mb20 ml12"
            required
            label="Enter Email"
            size="small"
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />
          <TextField
            className="width92 mb20 ml12"
            type="password"
            required
            label="Enter Password"
            size="small"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
          />
          <Grid item container xs={12} justifyContent="center">
            <Button
              className="login-btn"
              onClick={handleLogin}
              disabled={status === "loading"}
              style={{ background: "#039d9c", color: "#ffffff" }}
            >
              {status === "loading" ? "Logging in..." : "Login"}
            </Button>
          </Grid>
        </div>
      </Card>
    </Grid>
  );
};

export default Login;
