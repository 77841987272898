// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";

const token = localStorage.getItem('token')

export const fetchIMLCateAsync = createAsyncThunk('IMLcate/fetchList', async (dataToSend) => {
    try {
      const response = await axios.post(`${Base_url}/api/tcmargin/list`,{ ...dataToSend}, {
        headers: { Authorization: `${token}` },
      });
      return response.data.data;
    } catch (error) {
    //   return rejectWithValue(error.response.data);
    }
  });





  const iMLCateSlice = createSlice({
    name: 'imlcateList',
    initialState:{
        iMlCateValue : [],
        status: 'idle',
        error: '',
    },

    reducers: {},

    extraReducers: (builder) => {
        builder
          .addCase(fetchIMLCateAsync.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchIMLCateAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.iMlCateValue = action.payload;
          })
          .addCase(fetchIMLCateAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload?.message || 'An error occurred';
          })
      },
    

  })


  export default iMLCateSlice.reducer;