import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";

const token = localStorage.getItem("token");

// export const fetchTcPartnerList = createAsyncThunk("tcPartner/fetchList", async (searchValue) => {
//   try {
    
//     console.log(searchValue, 'sv');

//     // Initialize request body with empty name and mobile
//     let requestBody = {
//       name: '',
//       mobile: ''
//     };

//     // Check if searchValue is a valid mobile number
//     if (isMobileNumber(searchValue)) {
//       requestBody.mobile = searchValue;
//     } else {
//       requestBody.name = searchValue;
//     }


//     const response = await axios.post(
//       `${Base_url}/api/tcpartner/list`,
//       requestBody,
//       {
//         headers: { Authorization: `${token}` },
//       }
//     );
//     return response.data.data;
//   } catch (error) {
//     //   return rejectWithValue(error.response.data);
//   }
// });

// // Function to check if the searchValue is a valid mobile number
// function isMobileNumber(value) {
//   // Check if value is a string consisting of digits
//   return typeof value === 'string' && /^\d+$/.test(value);
// }


export const fetchTcPartnerList = createAsyncThunk(
  "tcPartner/fetchList",
  async (searchValue) => {
      // console.log(dataToSend,'pLL');
    try {
      const response = await axios.post(
        `${Base_url}/api/tcpartner/list`,
        { ...searchValue},
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching partner list:", error);
      throw error; // Rethrow the error to be handled in the component
    }
  }
);



export const createTcPartnerAsync = createAsyncThunk("tcPartner/createPartner", async (partnerData) => {
  try {
    const response = await axios.post(
      `${Base_url}/api/tcpartner/create`,
      {
        "name": partnerData.partnerName,
        "mobile": partnerData.partnerMobileNo,
        "city": '',
        "state": ''
      },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return response.data.data;
  } catch (error) {
    throw error; // Rethrow the error to be handled by the rejection case
  }
});



const TcPartnerSlice = createSlice({
    name: 'tcPartner',
    initialState: {
      partnerList: [],
      partnerDetails: {},
      status: 'idle',
      error: '',
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTcPartnerList.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchTcPartnerList.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.partnerList = action.payload;
        })
        .addCase(fetchTcPartnerList.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload?.message || 'An error occurred';
        })
        .addCase(createTcPartnerAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createTcPartnerAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
        })
        .addCase(createTcPartnerAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error?.message || 'An error occurred';
        });
        
    },
  });

  export default TcPartnerSlice.reducer;