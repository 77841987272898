import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";


const NotFound = () => {

  return (
    <section className="page_404" style={{ padding: "40px 0", background: "#fff", fontFamily: "'Arvo', serif" }}>
      <Box className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div className="four_zero_four_bg" style={{ backgroundImage: "url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)", height: "400px", backgroundPosition: "center" }}></div>
              <div className="contant_box_404" style={{ marginTop: "-50px" }}>
                <Typography variant="h1" className="text-center" style={{ fontSize: "40px" }}>No Data Found</Typography>
                <Typography variant="h3" style={{ fontSize: "30px" }}>Look like you're lost</Typography>
                <Typography variant="body1" style={{ fontSize: "16px" }}>The page you are looking for is not available!</Typography>
                <Link href="/" className="link_404" underline="none" style={{ color: "#fff!important", padding: "10px 20px", background: "white", margin: "20px 0", display: "inline-block" }}>Go to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </section>
  );
};

export default NotFound;