// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../../Base_url";
import axios from "axios";
import toast from "react-hot-toast";

const token = localStorage.getItem("token");

export const HospitalItems = createAsyncThunk(
  "onlyhospital/fetchListcate",
  async (dataToSend) => {
    try {
      const response = await axios.post(`${Base_url}/api/hospital-item/list`,{...dataToSend}, {
        headers: { Authorization: `${token}` },
      });
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);



export const AddHospitalItems = createAsyncThunk(
  "addhospital/onlyhospital",
  async (dataToSend) => {
    try {
      const response = await axios.post(`${Base_url}/api/hospital-item/create`,{...dataToSend}, {
        headers: { Authorization: `${token}` },
      });
      toast.success(response.data.message)
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }
);


export const hospitalitemDetails = createAsyncThunk(
  "hospital-details/onlyhospitals",
  async (dataToSend) => {
    try {
      const { id } = dataToSend;
      const response = await axios.get(`${Base_url}/api/hospital-item/details/${id}`, {
        headers: { Authorization: `${token}` },
      });
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);


export const hospitalitemEdit = createAsyncThunk(
  "hospital-edititem/onlyhospitals",
  async (dataToSend) => {
    try {
      const { id } = dataToSend;
      const response = await axios.post(`${Base_url}/api/hospital-item/update/${id}`,{...dataToSend}, {
        headers: { Authorization: `${token}` },
      });
      toast.success(response.data.message)
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);


export const hospitalitemDelete = createAsyncThunk(
  "hospital-deleteitem/onlyhospitals",
  async (dataToSend,{dispatch}) => {
    try {
      const { id } = dataToSend;
      const response = await axios.get(`${Base_url}/api/hospital-item/delete/${id}`, {
        headers: { Authorization: `${token}` },
      });
      dispatch(HospitalItems())
      toast.success(response.data.message);
      return response.data.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
    }
  }
);



const hosItemList = createSlice({
  name: "hospitalItemList",
  initialState: {
    hospitalItem: [],
    hospitalitemDetail:[],
    status: "idle",
    error: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(HospitalItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(HospitalItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hospitalItem = action.payload;
      })
      .addCase(HospitalItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(AddHospitalItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AddHospitalItems.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(AddHospitalItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

     
      .addCase(hospitalitemDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(hospitalitemDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hospitalitemDetail = action.payload;
      })
      .addCase(hospitalitemDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(hospitalitemEdit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(hospitalitemEdit.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(hospitalitemEdit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(hospitalitemDelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(hospitalitemDelete.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(hospitalitemDelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      });

  },
});

export default hosItemList.reducer;
