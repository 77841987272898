import React, { useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import '../pages/dashboard.css'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookingsListAsync } from '../Store/Features/BookingSlice';
import { fetchTcPartnerList } from '../Store/Features/TcPartnerSlice';
import { fetchTCTransactionsListAsync } from '../Store/Features/Tctransaction';
import { fetchHospitalBooking } from '../Store/Features/OnlyHospital/HospitalBooking';
import { fetchPartnerList } from '../Store/Features/OnlyHospital/HosPartner';
import { fetchHospitalTransation } from '../Store/Features/OnlyHospital/HospitalTransation';


const OnlyHosDashboard = () => {

  const dispatch = useDispatch()
  const bookingsList = useSelector((state) => state.hospitalBookingList.hospitalBooking?.data);
  const partnerList = useSelector((state) => state.hospitalPartnerList.hosPartner);
  const AnalyticsList = useSelector((state) => state.hosTransation?.hosTransationList?.data);
  const { user} = useSelector((state) => state.auth);

  const token = localStorage.getItem('token')

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     window.location.reload();
  //   }, 10000); 
  //   return () => clearInterval(intervalId); 
  // }, []);

  useEffect(() => {
    dispatch(fetchHospitalBooking())
    dispatch(fetchPartnerList(''))
    dispatch(fetchHospitalTransation())
  }, [dispatch,token, user])

  const inProgressBookingsCount = Array.isArray(bookingsList) && bookingsList ? bookingsList.filter(item => item.status === "Pending").length : 0;


  return (
    <>
      {/*********** body area start here ************/}
      <div>
        <Grid container spacing={2}>
          {/* item start */}
          <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'> Open Bookings</p>
                  <p className='sub-text-title'>{bookingsList ?  inProgressBookingsCount : 0}</p>
                </div>
                <div className='open-booking'>
                  <InfoIcon />
                </div>
              </div>

            </div>
            {/* <Card>
              <CardContent>
                <h2 className='m0'>Open Bookings (9)</h2>
              </CardContent>
            </Card> */}
          </Grid>
          {/* item end */}
          <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'> Bookings History</p>
                  <p className='sub-text-title'>{bookingsList ? bookingsList.length : 0}</p>
                </div>
                <div className='open-booking'>
                  <InfoIcon />
                </div>
              </div>


            </div>
          </Grid>
          {/* item start */}
          {/* <Grid item xs={3}>
            <Card>
              <CardContent>
                <h2 className='m0'>Bookings History (3)</h2>
              </CardContent>
            </Card>
          </Grid> */}

          {/* item end */}

          {/* item start */}
          {/* <Grid item xs={3}>
            <Card>
              <CardContent>
                <h2 className='m0'>TC Partner</h2>
              </CardContent>
            </Card>
          </Grid> */}
          <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'> TC Partner</p>
                  <p className='sub-text-title'>{partnerList ? partnerList.length : 0}</p>
                </div>
                <div className='partner-icon'>
                  <GroupsIcon />
                </div>
              </div>

            </div>
          </Grid>
          {/* item end */}

          {/* item start */}
          {/* <Grid item xs={3}>
            <Card>
              <CardContent>
                <h2 className='m0'>TC Analytics</h2>
              </CardContent>
            </Card>
          </Grid> */}
          <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'> TC Analytics</p>
                  {Array.isArray(AnalyticsList) && AnalyticsList.some(item => item?.booking_id) ? (
                    <p className='sub-text-title'>{AnalyticsList.filter(item => item?.booking_id).length}</p>
                  ) : <p className='sub-text-title'>0</p>}
                </div>
                <div className='analytics'>
                  <SignalCellularAltRoundedIcon />
                </div>
              </div>

            </div>
          </Grid>
          {/* item end */}

          {/* item start */}
          {/* <Grid item xs={3}>
            <Card>
              <CardContent>
                <h2 className='m0'>Payment Settlement</h2>
              </CardContent>
            </Card>
            
          </Grid> */}
          <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'> Payment Settlement</p>
                  <p className='sub-text-title'>{AnalyticsList ? AnalyticsList.length : 0}</p>
                </div>
                <div className='Account-icon'>
                  <AccountBalanceIcon />
                </div>
              </div>

            </div>
          </Grid>
          {/* item end */}

          {/* item start */}
          {/* <Grid item xs={3}>
            <Card>
              <CardContent>
                <h2 className='m0'>Profile</h2>
              </CardContent>
            </Card>
          </Grid> */}
          {/* <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'> Profile</p>
                  <p className='sub-text-title'>1,233</p>
                </div>
                <div className='person-icon'>
                  <PersonAddAltIcon />
                </div>
              </div>

            </div>
          </Grid> */}
          {/* item end */}

          {/* item start */}
          {/* <Grid item xs={3}>
            <Card>
              <CardContent>
                <h2 className='m0'>About TC</h2>
              </CardContent>
            </Card>
          </Grid> */}
          {/* item end */}
          {/* <Grid item xs={3}>
            <div className='cardName'>
              <div className='cardDetail'>
                <div>
                  <p className='text-title'>      About TC</p>
                  <p className='sub-text-title'>1,233</p>
                </div>
                <div className='about-us'>
                  <InfoIcon />
                </div>
              </div>

            </div>
          </Grid> */}
        </Grid>
      </div>
      {/************ body area end here ************/}
    </>
  )
}

export default OnlyHosDashboard