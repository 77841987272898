// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";
import toast from "react-hot-toast";
import { fetchIMLListAsync } from "./IMLSlice";


const token = localStorage.getItem("token");

export const bulkupload = createAsyncThunk(
  "item/bulkupload",
  async (selectedFile , { rejectWithValue, dispatch }) => {
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("excel_file", selectedFile);

      const response = await axios.post(
        `${Base_url}/api/item/bulk-upload`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart form-data
          },
        }
      );
      toast.success(response.data?.message);
      dispatch(fetchIMLListAsync())
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);


export const bulkuploadHosital = createAsyncThunk(
  "itemhos/bulkuploads",
  async (selectedFile , { rejectWithValue, dispatch }) => {
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("excel_file", selectedFile);

      const response = await axios.post(
        `${Base_url}/api/hospital-item/bulk-upload`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart form-data
          },
        }
      );
      toast.success(response.data?.message);
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);



export const bulkuploadHos = createAsyncThunk(
  "itemhospital/bulkuploads",
  async (selectedFile , { rejectWithValue, dispatch }) => {
    try {
      // Create FormData object
      const formData = new FormData();
      formData.append("excel_file", selectedFile);

      const response = await axios.post(
        `${Base_url}/api/item/hospital/bulk-upload`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart form-data
          },
        }
      );
      toast.success(response.data?.message);
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);



const bulkItemUpload = createSlice({
  name: "bulkuploads",
  initialState: {
    bulkValue: [],
    bulkValueHospital: [],
    bulkhospitalItem:[],
    status: "idle",
    error: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(bulkupload.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkupload.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bulkValue = action.payload;
      })
      .addCase(bulkupload.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(bulkuploadHosital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkuploadHosital.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bulkValueHospital = action.payload;
      })
      .addCase(bulkuploadHosital.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      
      .addCase(bulkuploadHos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkuploadHos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bulkhospitalItem = action.payload;
      })
      .addCase(bulkuploadHos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      });

  },
});

export default bulkItemUpload.reducer;
