import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchIMLCateAsync } from "../Store/Features/IMlCateListSlice";
import {
  createIMLListAsync,
  fetchIMLListAsync,
} from "../Store/Features/IMLSlice";
import { useNavigate } from "react-router-dom";

const AddItemMaster = () => {
  const [inputData, setInputData] = useState({
    itemName: '',
    itemGroup: '',
    TCMarginCategory: '',
    price: '',
    tc_margin_commission_percentage :''
  });

  const [errors, setErrors] = useState({
    itemName: false,
    // itemGroup: false,
    TCMarginCategory: false,
    price: false,
    tc_margin_commission_percentage : false
  });

  const navigate = useNavigate();
  const imlCateList = useSelector((state) => state.imlcateList?.iMlCateValue);
  const dispatch = useDispatch();


  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    // Convert tc_margin_commission_percentage to a number
    const parsedValue = name === 'tc_margin_commission_percentage' ? Number(value) : value;
  
    setInputData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));
  
    // Clear error for the field when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleCategoryChange = (event) => {
    setInputData((prevData) => ({
      ...prevData,
      TCMarginCategory: event.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      TCMarginCategory: false,
    }));
  };

  const handleSubmit = () => {
    // Check for any empty fields
    const newErrors = {};
    let hasErrors = false;

  Object.keys(inputData).forEach((key) => {
    if (key !== 'itemGroup' && !inputData[key]) {
      newErrors[key] = true;
      hasErrors = true;
    }
  });

    if (hasErrors) {
      setErrors(newErrors);
    } else {
      // Handle submission logic here using inputData
      dispatch(createIMLListAsync(inputData));
      navigate('/testsDetails');
    }
  };

  useEffect(() => {
    // Add any necessary dispatch calls for fetching data here
    // dispatch(fetchIMLCateAsync());
    // dispatch(fetchIMLListAsync());
    dispatch(fetchIMLCateAsync())
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <h2 className="m0 mb10 iconTitel">
            <ContentPasteGoOutlinedIcon /> Add New Test
          </h2>
          <Paper className="customTable" sx={{ width: '100%' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow hover sx={{ 'td,th': { border: 1 } }}>
                    <TableCell>Test Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Price (₹)</TableCell>
                    <TableCell>TC Margin (%)</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover sx={{ 'td,th': { border: 1 } }}>
                    <TableCell>
                      <TextField
                        label="Test Name"
                        name="itemName"
                        value={inputData.itemName}
                        onChange={handleInputChange}
                        error={errors.itemName}
                        helperText={errors.itemName ? 'Test Name is Required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Category"
                          value={inputData.TCMarginCategory}
                          onChange={handleCategoryChange}
                          error={errors.TCMarginCategory}
                        >
                          {Array.isArray(imlCateList) &&
                            imlCateList.map((item) => (
                              <MenuItem value={item._id} key={item._id}>
                                {item.group_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Group"
                        name="itemGroup"
                        value={inputData.itemGroup}
                        onChange={handleInputChange}
                        // error={errors.itemGroup}
                        // helperText={errors.itemGroup ? 'This field is required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Price"
                        name="price"
                        value={inputData.price}
                        onChange={handleInputChange}
                        error={errors.price}
                        helperText={errors.price ? 'Price is Required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="TC Margin"
                        name="tc_margin_commission_percentage"
                        type="Number"
                        value={inputData.tc_margin_commission_percentage }
                        onChange={handleInputChange}
                        error={errors.tc_margin_commission_percentage}
                        helperText={errors.tc_margin_commission_percentage ? 'TC Margin Commission (%) is Required' : ''}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        endIcon={<PostAddOutlinedIcon />}
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default AddItemMaster;