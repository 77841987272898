//import logo from './logo.svg';
import './App.css';

import Header from './header/Header';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import ScrollToTop from './ScrollToTop/ScrollToTop.jsx';
import SideBar from './sidebar/SideBar.jsx';
import { CssBaseline } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MainScreen from './pages/MainScreen.jsx';
import OpenBookings from './booking/OpenBookings.jsx';
import CategoryDetails from './booking/CategoryDetails.jsx';
import BookingsHistory from './booking/BookingsHistory.jsx';
import OpenBookingDetails from './booking/OpenBookingDetails.jsx';
import TestsDetails from './booking/TestsDetails.jsx';
import Login from './login/Login.jsx';
import MyProfile from './my-profile/MyProfile.jsx';
import { useSelector , useDispatch } from 'react-redux';
import AddItemMaster from './Components/AddItemMaster.jsx';
import EditItemMaster from './Components/EditItemMaster.jsx';
import AddBooking from './Components/AddBooking.jsx';
import TcAnalytics from './booking/TcAnalytics.jsx';
import TcPartner from './booking/TcPartner.jsx';
import HistoryBookingDetails from './booking/HistoryBookingDetails.jsx';
import PaymentSettlement from './booking/PaymentSettlement.jsx';
import { getUserDetailsAsync } from './Store/Features/AuthSlice.js';
import NotFound from './Components/NotFound.jsx';
import HosCategory from './Hospital/HosCategory.jsx';
import HosAnalytics from './Hospital/HosAnalytics.jsx';
import HosItems from './Hospital/HosItems.jsx';
import HosSettlement from './Hospital/HosSettlement.jsx';
import HosBookingHistory from './Hospital/HosBookingHistory.jsx';
import HosOpenBooking from './Hospital/HosOpenBooking.jsx';
import HospitalDashboard from './Hospital/HospitalDashboard.jsx';
import AddBed from './Hospital/AddBed.jsx';
import HosEditBed from './Hospital/HosEditBed.jsx';
import HosHistoryBookingDetails from './Hospital/HosHistoryBookingDetails.jsx';
import AddHosBooking from './Hospital/AddHosBooking.jsx';
import HosOpenBookingDetails from './Hospital/HosOpenBookingDetails.jsx';
import OnlyHosCatagory from './OnlyHospital/OnlyHosCatagory.jsx';
import OnlyHosItem from './OnlyHospital/OnlyHosItem.jsx';
import OnlyHosAddBed from './OnlyHospital/OnlyHosAddBed.jsx';
import OnlyHosEditBed from './OnlyHospital/OnlyHosEditBed.jsx';
import OnlyHosOpenbooking from './OnlyHospital/OnlyHosOpenbooking.jsx';
import OnlyHosBookingHistory from './OnlyHospital/OnlyHosBookingHistory.jsx';
import OnlyHosHistoryBookingDetails from './OnlyHospital/OnlyHosHistoryBookingDetails.jsx';
import OnlyHosOpenBookingDetails from './OnlyHospital/OnlyHosOpenBookingDetails.jsx';
import OnlyHosAnalytics from './OnlyHospital/OnlyHosAnalytics.jsx';
import OnlyHosSettlement from './OnlyHospital/OnlyHosSettlement.jsx';
import OnlyHosAddBooking from './OnlyHospital/OnlyHosAddBooking.jsx';
import OnlyHosDashboard from './OnlyHospital/OnlyHosDashboard.jsx';
import OnlyHospitalpartner from './OnlyHospital/OnlyHospitalpartner.jsx';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

function App() {
  const theme = useTheme();
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {token , user} = useSelector((state) => state.auth);
  const isloginpage = location.pathname === '/login'


  const isAuthenticated = () => {
    return !!token && !!user;
  };

  
  useEffect(() => {
    if (token) {
      dispatch(getUserDetailsAsync(token));
    }
  }, [dispatch]);

  
  useEffect(() => {
  if (!isAuthenticated() && !isloginpage) {
    navigate('/login');
  }
}, [token, isloginpage, navigate]);


  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  return (
    <>
      {/* main wrapper start */}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        {/****** top bar part start *******/}
        {
          !isloginpage ?
            <>
              <Header open={open} setOpen={setOpen} />

              <SideBar open={open} setOpen={setOpen} />
            </>
            :
            null
        }
        {/******* sidebar part end *******/}

        {/******* body part start *******/}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Routes>
            <Route exact path='/' element={<MainScreen />} />
            <Route path='/openBookings' element={<OpenBookings />} />
            <Route path='/openbookingdetails/:id' element={<OpenBookingDetails />} />
            <Route path='/historybookingdetails/:id' element={<HistoryBookingDetails />} />
            <Route path='/bookingsHistory' element={<BookingsHistory />} />
            <Route path='/categoryDetails' element={<CategoryDetails />} />
            <Route path='/testsDetails' element={<TestsDetails />} />
            <Route path='/paymentSettlement' element={<PaymentSettlement />} />
            <Route path='/analytics' element={<TcAnalytics />} />
            <Route path='/partner' element={<TcPartner />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/MyProfile' element={<MyProfile />} />
           <Route path='/addItemMaster' element={<AddItemMaster/>} />
           <Route path='/editIM/:id' element={<EditItemMaster/>} />
           <Route path='/addbooking' element={<AddBooking/>} />


                 {/* hospital Route */}
           <Route path='/hospital_dashboard' element={<HospitalDashboard/>} />
           <Route path='/hospital-category' element={<HosCategory/>} />
           <Route path='/hospital-analytics' element={<HosAnalytics/>} />
           <Route path='/hospital-Item' element={<HosItems/>} />
           <Route path='/hospital-settlement' element={<HosSettlement/>} />   
           <Route path='/hospital-bookinghistory' element={<HosBookingHistory/>} />
           <Route path= '/hospital-booking' element={<HosOpenBooking/>} />
           <Route path='/addbedItem' element={<AddBed/>} />
           <Route path='/hospitalItem-edit/:id' element={<HosEditBed/>} />
           <Route path='/hospitalHistoryBookingDetails/:id' element={<HosHistoryBookingDetails/>} />
           <Route path='/addHospitalBooking' element={<AddHosBooking/>} />
           <Route path='/hospital-OpenBooking-Details/:id' element={<HosOpenBookingDetails/>} />


              {/* only Hospital Route */}

          <Route path='/onlyHospital-category' element={<OnlyHosCatagory/>} />
          <Route path='/onlyHospital-Item' element={<OnlyHosItem/>} />
          <Route path='/onlyHospitalAddBed' element={<OnlyHosAddBed/>} />
          <Route path='/onlyHospital-edit/:id' element={<OnlyHosEditBed/>} />
          <Route path='/onlyHospital-openbooking' element={<OnlyHosOpenbooking/>} />
          <Route path='/onlyHospital-bookingHistory' element={<OnlyHosBookingHistory/>} />
          <Route path='/onlyHospital-bookingHistoryDetails/:id' element={<OnlyHosHistoryBookingDetails/>} />
          <Route path='/onlyHospital-openbookingDetails/:id' element={<OnlyHosOpenBookingDetails/>} />
          <Route path='/onlyHospital-analytics' element={<OnlyHosAnalytics/>} />
          <Route path='/onlyHospital-settlement' element={<OnlyHosSettlement/>} />
          <Route path='/OnlyHospital-addBooking' element={<OnlyHosAddBooking/>} /> 
          <Route path='/hospital-dashboard' element={<OnlyHosDashboard/>} />
          <Route path='/onlyHospital-partners' element={<OnlyHospitalpartner/>} />
           <Route path='/*' element={<NotFound/>} />
          </Routes>
        </Box>
        {/******* body part end *******/}

      </Box>
      {/* main wrapper start */}
    </>
  );
}

export default App;
