// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../../Base_url";
import axios from "axios";

const token = localStorage.getItem('token')

export const fetchPartnerList = createAsyncThunk(
    "Partner/fetchList",
    async (searchValue) => {
      try {
        const response = await axios.post(
          `${Base_url}/api/tcpartner/hospital/list`,
          { ...searchValue},
          {
            headers: { Authorization: `${token}` },
          }
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching partner list:", error);
        throw error; 
      }
    }
  );
  





  const hospitalPartner = createSlice({
    name: 'hospitalPartnerList',
    initialState:{
        hosPartner : [],
        status: 'idle',
        error: '',
    },

    reducers: {},

    extraReducers: (builder) => {
        builder
          .addCase(fetchPartnerList.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchPartnerList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.hosPartner = action.payload;
          })
          .addCase(fetchPartnerList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload?.message || 'An error occurred';
          })
      },
    

  })


  export default hospitalPartner.reducer;