// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../../Base_url";
import axios from "axios";
import toast from "react-hot-toast";

const token = localStorage.getItem("token");

export const fetchHospitalBooking = createAsyncThunk(
  "booking/fetchList",
  async (dataToSend) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/hospital/booking/list`,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response?.data;
    } catch (error) {
      // return rejectWithValue(error.response.data);
    }
  }
);

export const HospitalBookingDetails = createAsyncThunk(
  "bookingdetails/fetchbooking",
  async (dataToSend) => {
    try {
      const { id } = dataToSend;
      const response = await axios.get(
        `${Base_url}/api/hospital/booking/details/${id}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      return response?.data;
    } catch (error) {
      // return rejectWithValue(error.response.data);
    }
  }
);

export const updateBookingOpenBooking = createAsyncThunk(
  "openbookings/updateopenBookings",
  async (
    { id, bookingData, prescriptionFiles, type },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { Items, ...restBookingData } = bookingData;
      const formData = new FormData();

      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("prescription", prescriptionFiles[i]);
      }

      Items.forEach((item, index) => {
        formData.append(`Items[${index}][total_beds]`, item.total_beds);
        formData.append(`Items[${index}][item_name]`, item.item_name);
        formData.append(
          `Items[${index}][per_day_charges]`,
          item.per_day_charges
        );
        formData.append(`Items[${index}][item_discount]`, item.item_discount);
        formData.append(
          `Items[${index}][hospital_item_id]`,
          item.hospital_item_id
        );
      });

      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/hospital/booking/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { message } = response?.data;

      const dataToSend = {
        selecetedId: response?.data?.data?._id,
        status: "Paid",
        remarks: "",
      };
      dispatch(updateBookingStatus(dataToSend));

      toast.success(message);
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response?.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const updateBookingStatus = createAsyncThunk(
  "bookingpayment/updatepaymentStatus",
  async ({ selecetedId, status, remarks }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/hospital/booking/status/update/${selecetedId}`,
        { payment_status: status, remarks: remarks },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { message } = response?.data;
      // toast.success(message || "Update success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          // error.response.data?.message || "An error occurred";
          toast.error(errorMessage || "failed");
        return rejectWithValue(error.response.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const removeBookingItem = createAsyncThunk(
  "booking/removeItem",
  async ({ id, itemId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/hospital/booking/item/remove/${id}`,
        { itemId: itemId },
        {
          headers: { Authorization: `${token}` },
        }
      );
      const { message } = response?.data;
      toast.success(message || "removed success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response?.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const createHospitalBooking = createAsyncThunk(
  "bookinghos/create",
  async (
    { bookingData, prescriptionFiles },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { Items, ...restBookingData } = bookingData;
      const formData = new FormData();

      // Append prescription files
      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("prescription", prescriptionFiles[i]);
      }
      
      // Append items data
      Items.forEach((item, index) => {
        formData.append(`Items[${index}][total_beds]`, item.total_beds);
        formData.append(`Items[${index}][item_name]`, item.item_name);
        formData.append(
          `Items[${index}][per_day_charges]`,
          item.per_day_charges
        );
        formData.append(`Items[${index}][item_discount]`, item.item_discount);
        formData.append(
          `Items[${index}][hospital_item_id]`,
          item.hospital_item_id
        );
      });

      // Append other booking data
      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/hospital/booking/create`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch(
        updateBookingStatus({
          selecetedId: response?.data?.data?._id,
          status: "Paid",
          remarks: "",
        })
      );
      const dataToSend ={
        status: "In Progress"
      }
      dispatch(fetchHospitalBooking(dataToSend));
      toast.success(response.data?.message);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStatusBooking = createAsyncThunk(
  "bookingStatus/deleteStatus",
  async ({ selecetedId, status }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${Base_url}/api/hospital/booking/status/update/${selecetedId}`,
        { status: status },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(fetchHospitalBooking());
      const { message } = response?.data;
      toast.success(message || "delete success");
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
          toast.error(errorMessage || "something went wrong to delete");
        return rejectWithValue(error.response.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);


export const updateBookingReport = createAsyncThunk(
  "booking/update",
  async (
    { id, bookingData,type, prescriptionFiles },
    { rejectWithValue, dispatch }
  ) => {
    try {
      // Extracting Items from bookingData
      const { Items, ...restBookingData } = bookingData;

      // Creating FormData
      const formData = new FormData();
      
      for (let i = 0; i < prescriptionFiles.length; i++) {
        formData.append("report", prescriptionFiles[i]);
      }

  
        Items.forEach((item, index) => {
          formData.append(`Items[${index}][total_beds]`, item.total_beds);
          formData.append(`Items[${index}][item_name]`, item.item_name);
          formData.append(`Items[${index}][per_day_charges]`, item.per_day_charges);
          formData.append(`Items[${index}][item_discount]`, item.item_discount);
          formData.append(`Items[${index}][hospital_item_id]`, item.hospital_item_id);
        });
    

     
      Object.keys(restBookingData).forEach((key) => {
        formData.append(key, restBookingData[key]);
      });

      const response = await axios.post(
        `${Base_url}/api/hospital/booking/update/${id}`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { message } = response?.data;

        toast.success(message);
        return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data?.message || "An error occurred";
        toast.error(errorMessage);
        return rejectWithValue(error.response?.data);
      } else {
        toast.error("An error occurred");
        return rejectWithValue(error?.message);
      }
    }
  }
);



const HospitalBooking = createSlice({
  name: "hospitalBookingList",
  initialState: {
    hospitalBooking: [],
    hospitalBookingDetails: {},
    status: "idle",
    error: "",
    message: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHospitalBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hospitalBooking = action.payload;
      })
      .addCase(fetchHospitalBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      .addCase(HospitalBookingDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(HospitalBookingDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hospitalBookingDetails = action.payload;
      })
      .addCase(HospitalBookingDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      .addCase(updateBookingStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBookingStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      .addCase(updateBookingOpenBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBookingOpenBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingOpenBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
        state.message = action.payload?.message;
      })

      .addCase(removeBookingItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeBookingItem.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removeBookingItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      .addCase(deleteStatusBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteStatusBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(deleteStatusBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(createHospitalBooking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createHospitalBooking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(createHospitalBooking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(updateBookingReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBookingReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.message;
      })
      .addCase(updateBookingReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      });


  },
});





export default HospitalBooking.reducer;
