import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Base_url from '../../Base_url';

const token = localStorage.getItem('token');

export const fetchTCTransactionsListAsync = createAsyncThunk('tctransaction/fetchList', async (dataToSend) => {
  try {
    // console.log(dataToSend,'DS');
    const response = await axios.post(`${Base_url}/api/tctransaction/list`,{
      ...dataToSend
    }, {
      headers: { Authorization: `${token}` },
    });
    return response.data;
  } catch (error) {
    // throw error.response.data; 
  }
});


export const exportAnalytics = createAsyncThunk('transaction/analyticsCsv', async (dataToSend) => {
  try {
    // console.log(dataToSend,'DS');
    const response = await axios.post(`${Base_url}/api/tctransaction/diagnostic/analytics/list`,{
      ...dataToSend
    }, {
      headers: { Authorization: `${token}` },
    });
    return response.data;
  } catch (error) {
    // throw error.response.data; 
  }
});


export const analyticsAll = createAsyncThunk('transactiondata/analyticsall', async (dataToSend) => {
  try {
    // console.log(dataToSend,'DS');
    const response = await axios.post(`${Base_url}/api/tctransaction/diagnostic/analytics/list`,{
      ...dataToSend
    }, {
      headers: { Authorization: `${token}` },
    });
    return response.data;
  } catch (error) {
    // throw error.response.data; 
  }
});



export const debitTCTransactionAsync = createAsyncThunk('tctransaction/debit', async (debitData,{rejectWithValue, dispatch}) => {
  try {
    const {type} = debitData || {};
    console.log(type ,'type') ;
    const formData = new FormData();

    // Convert debitData object to form data
    for (const key in debitData) {
      formData.append(key, debitData[key]);
    }

    const response = await axios.post(`${Base_url}/api/tctransaction/debit`, formData, {
      headers: { Authorization: `${token}`, 'Content-Type': 'multipart/form-data' },
    });
    if(type){
      dispatch(fetchTCTransactionsListAsync({
        start_date: '',
        end_date: '',
        type:1
      }))
    }else{
      dispatch(fetchTCTransactionsListAsync({
        start_date: '',
        end_date: '',
      }))
    }
    return response.data.data;
  } catch (error) {
    // Handle error appropriately
    // throw error.response.data;
  }
});


const tctransactionSlice = createSlice({
  name: 'tctransaction',
  initialState: {
    transactionsList: [],
    analyticsCSV:[],
    analyticsalldata:[],
    status: 'idle',
    status1:'idle',
    error: '',
    message:''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTCTransactionsListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTCTransactionsListAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transactionsList = action.payload;
      })
      .addCase(fetchTCTransactionsListAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.message || 'An error occurred';
      })
      .addCase(debitTCTransactionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(debitTCTransactionAsync.fulfilled, (state, action) => {
        state.message = action.payload?.message
      })
      .addCase(debitTCTransactionAsync.rejected, (state, action) => {
        state.message = action.payload?.message
      })


      .addCase(exportAnalytics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(exportAnalytics.fulfilled, (state, action) => {
        state.message = action.payload?.message
        state.analyticsCSV= action.payload
      })
      .addCase(exportAnalytics.rejected, (state, action) => {
        state.message = action.payload?.message
      })


      .addCase(analyticsAll.pending, (state) => {
        state.status1 = 'loading';
      })
      .addCase(analyticsAll.fulfilled, (state, action) => {
        state.message = action.payload?.message
        state.analyticsalldata= action.payload
      })
      .addCase(analyticsAll.rejected, (state, action) => {
        state.message = action.payload?.message
      });


  },
});

export default tctransactionSlice.reducer;