import React, { useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useParams, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBookingDetailsAsync,
  updateBookingAsync,
  removeBookingItemAsync,
  addBookingItemAsync,
} from "../Store/Features/BookingSlice";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import IconButton from "@mui/material/IconButton";

import { fetchIMLListAsync1 } from "../Store/Features/IMLSlice";
import toast from "react-hot-toast";
import { Tooltip, Typography } from "@mui/material";

const HosHistoryBookingDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [items, setItems] = useState([
    // { item_name: "", item_price: "", item_discount: "", itemId: "" },
  ]);

  const [reportFile, setReportFile] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tatkalCaresDiscount, setTatkalCaresDiscount] = useState(0);
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);

  const navigate = useNavigate();

  const bookingDetail = useSelector((state) => state.booking?.bookingDetails);
  const itemMaster = useSelector((state) => state.imlList?.itemMasterList);
  const message = useSelector((state) => state.booking?.message);

  const handlePrescriptionFileChange = (e) => {
    const files = e.target.files;
    const newFiles = [...prescriptionFiles];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= 10 * 1024 * 1024) {
        // 5MB limit
        newFiles.push(files[i]);
      } else {
        toast.error("File size should be less than 10MB ");
      }
    }
    setPrescriptionFiles(newFiles);
  };

  const handleRemovePrescriptionFile = (index) => {
    const updatedFiles = [...prescriptionFiles];
    updatedFiles.splice(index, 1);
    setPrescriptionFiles(updatedFiles);
  };

  useEffect(() => {
    const dataToSend = {
      id: id,
      type: 1,
    };
    dispatch(fetchBookingDetailsAsync(dataToSend));
  }, [dispatch, id]);

  useEffect(() => {
    const fetchdata = () => {
      const dataToSend = {
        type: 1,
      };
      dispatch(fetchIMLListAsync1(dataToSend));
    };
    fetchdata();
  }, [dispatch]);

  const {
    Items,
    booking_date,
    booking_id,
    city,
    discount_price,
    doctor_name,
    doctor_number,
    final_amount,
    partner_name,
    partner_number,
    patient_name,
    patient_number,
    prescription,
    remarks,
    report,
    status,
  } = bookingDetail || {};

  useEffect(() => {
    if (Array.isArray(items)) {
      const calculatedAmounts = items.reduce(
        (acc, item) => {
          acc.total += parseFloat(item.per_day_charges) || 0;
          const discountAmount =
            (parseFloat(item.item_discount) / 100) *
            (parseFloat(item.per_day_charges) || 0);
          acc.discount += discountAmount || 0;
          return acc;
        },
        { total: 0, discount: 0 }
      );

      setTotalAmount(calculatedAmounts.total);
      setTatkalCaresDiscount(calculatedAmounts.discount);
    }
  }, [items]);

  useEffect(() => {
    console.log(bookingDetail, "BD");
    if (bookingDetail && bookingDetail.Items) {
      setItems(
        bookingDetail.Items.map((item) => ({
          _id: item._id,
          item_name: item.item_name,
          total_beds: item.total_beds,
          per_day_charges: item.per_day_charges,
          item_discount: item.item_discount,
          hospital_item_id: item.hospital_item_id,
        }))
      );
    }
  }, [bookingDetail]);
  console.log(items, "obj");

  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  const handleBookingSubmit = () => {
    if (!prescriptionFiles) {
      toast.error("please upload report to submit the form");
      return;
    }
    const bookingData = {
      Items: items,
      booking_date,
      booking_id,
      city,
      discount_price,
      doctor_name,
      doctor_number,
      final_amount,
      partner_name,
      partner_number,
      patient_name,
      patient_number,
      status: "Completed",
    };

    const type = 1;

    dispatch(updateBookingAsync({ id, bookingData, type, prescriptionFiles }));
    // toast.success(message)
    navigate("/hospital-bookinghistory");
  };

  const handleDownloadPrescription = (prescriptionUrl) => {
    const link = document.createElement("a");
    link.href = prescriptionUrl;
    link.target = "_blank";
    link.click();
  };

  const handleDownloadReport = (reportUrl) => {
    const link = document.createElement("a");
    link.href = reportUrl;
    link.target = "_blank";
    link.click();
  };

  return (
    // page body part start //
    <div className="mb30">
      <Grid className="mb10" container spacing={3}>
        {/* Patient Information part start */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <AccountCircleOutlinedIcon /> Patient Information
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Booking ID</b>
                        </TableCell>
                        <TableCell>{booking_id}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Patient Name</b>
                        </TableCell>
                        <TableCell>{patient_name}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Patient Mobile No</b>
                        </TableCell>
                        <TableCell>{patient_number}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Status</b>
                        </TableCell>
                        <TableCell>{status}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Prescription</b>
                        </TableCell>
                        <TableCell>
                          {prescription?.length === 1 ? (
                            <IconButton
                              color="success"
                              variant="outlined"
                              size="small"
                              onClick={() =>
                                handleDownloadPrescription(prescription[0])
                              }
                              target="_blank"
                              download
                            >
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          ) : prescription?.length === 0 ? (
                            <IconButton
                              color="error" // Setting the color to red
                              variant="outlined"
                              size="small"
                              disabled
                              style={{ color: "red" }}
                            >
                              <CloudDownloadOutlinedIcon />
                            </IconButton>
                          ) : (
                            <Select
                              defaultValue=""
                              onChange={(e) =>
                                handleDownloadPrescription(e.target.value)
                              }
                              displayEmpty
                            >
                              <MenuItem disabled value="">
                                Select
                              </MenuItem>
                              {Array.isArray(prescription) &&
                                prescription.map((prescriptionUrl, index) => (
                                  <MenuItem key={index} value={prescriptionUrl}>
                                    Prescription {index + 1}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Report</b>
                        </TableCell>
                        {status !== "Completed" && (
                          <TableCell>
                            <input
                              type="file"
                              id="report-upload-input"
                              style={{ display: "none" }}
                              multiple
                              onChange={handlePrescriptionFileChange}
                            />
                            <label htmlFor="report-upload-input">
                              <Button
                                variant="outlined"
                                // color="warning"
                                component="span"
                                endIcon={<CloudUploadOutlinedIcon />}
                              >
                                Upload
                              </Button>
                            </label>
                          </TableCell>
                        )}
                        {status === "Completed" && (
                          <TableCell>
                            {report?.length === 1 ? (
                              <IconButton
                                color="success"
                                variant="outlined"
                                size="small"
                                onClick={() => handleDownloadReport(report[0])}
                                target="_blank"
                                download
                              >
                                <CloudDownloadOutlinedIcon />
                              </IconButton>
                            ) : report?.length === 0 ? (
                              <IconButton
                                color="error" // Setting the color to red
                                variant="outlined"
                                size="small"
                                disabled
                                style={{ color: "red" }}
                              >
                                <CloudDownloadOutlinedIcon />
                              </IconButton>
                            ) : (
                              <Select
                                defaultValue=""
                                onChange={(e) =>
                                  handleDownloadReport(e.target.value)
                                }
                                displayEmpty
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {Array.isArray(report) &&
                                  report.map((reportUrl, index) => (
                                    <MenuItem key={index} value={reportUrl}>
                                      Report {index + 1}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          </TableCell>
                        )}
                      </TableRow>

                      {status !== "Completed" && (
                        <TableRow hover sx={{ "td,th": { border: 1 } }}>
                          <TableCell sx={{ width: 175 }}>Show Report</TableCell>
                          <TableCell
                            style={{
                              border: "1px solid #ccc",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {/* Render selected prescription files with remove buttons */}
                              {prescriptionFiles.map((file, index) => (
                                <div
                                  key={index}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <span>{file.name}</span>
                                  <Tooltip title="Delete" placement="right">
                                    <Button
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      onClick={() =>
                                        handleRemovePrescriptionFile(index)
                                      }
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Patient Information part end */}

        {/* Partner Information part start */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <SupervisedUserCircleOutlinedIcon /> Partner Information
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>
                          <b>Partner Name</b>
                        </TableCell>
                        <TableCell>{partner_name}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Partner Mobile No</b>
                        </TableCell>
                        <TableCell>{maskPhoneNumber(partner_number)}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>OPD Appointment Date</b>
                        </TableCell>
                        <TableCell>
                          {booking_date
                            ? new Date(booking_date).toLocaleDateString("en-GB")
                            : "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Doctor's Name</b>
                        </TableCell>
                        <TableCell>{doctor_name}</TableCell>
                      </TableRow>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell sx={{ width: 175 }}>
                          <b>Doctor's Number</b>
                        </TableCell>
                        <TableCell>{doctor_number}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Partner Information part end */}

        {/* Add Test part start */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h2 className="m0 mb10 iconTitel">
                <ContentPasteGoOutlinedIcon /> Bed
              </h2>
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow hover sx={{ "td,th": { border: 1 } }}>
                        <TableCell>Bed Name</TableCell>
                        <TableCell>Total Bed</TableCell>
                        <TableCell>Test Amount</TableCell>
                        <TableCell>TatkalCares Discount</TableCell>
                        {/* <TableCell>
                          <Button
                            variant="contained"
                            endIcon={<PostAddOutlinedIcon />}
                            style={{ marginLeft: "15px", marginBottom: "10px" }}
                            onClick={handleAddRow}
                          >
                            {" "}
                            Add
                          </Button>
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(items) &&
                        items.map((element, index) => (
                          <TableRow
                            key={index}
                            hover
                            sx={{ "td,th": { border: 1 } }}
                          >
                            <TableCell>
                              <Typography>{element.item_name}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{element.total_beds}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{element.per_day_charges}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{element.item_discount}%</Typography>
                            </TableCell>
                            {/* <TableCell>
              <Button
                color="error"
                variant="contained"
                endIcon={<BookmarkRemoveOutlinedIcon />}
                onClick={() => handleRemoveRow(index,element.itemId)}
              >
                Remove
              </Button>
            </TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          </Card>
        </Grid>
        {/* Add Test part end */}

        {/* tac part start here */}
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Total Amount</b>
                      </TableCell>
                      <TableCell>{totalAmount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Tc Discount</b>
                      </TableCell>
                      <TableCell>{tatkalCaresDiscount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow sx={{ "td,th": { border: 1 } }}>
                      <TableCell>
                        <b>Final Payment</b>
                      </TableCell>
                      <TableCell>
                        {totalAmount.toFixed(2) -
                          tatkalCaresDiscount.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {status === "In Progress" && (
                  <Button
                    className="mt20"
                    variant="contained"
                    onClick={handleBookingSubmit}
                    endIcon={<SendIcon />}
                  >
                    Submit
                  </Button>
                )}
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        {/* tac part end here */}
      </Grid>
    </div>
    // page body part end //
  );
};

export default HosHistoryBookingDetails;