import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { fetchTcPartnerList } from "../Store/Features/TcPartnerSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { Tabs, Tab } from "@mui/material";
import { fetchPartnerList } from "../Store/Features/OnlyHospital/HosPartner";
const OnlyHospitalpartner = () => {
  const dispatch = useDispatch();

  const partnerList = useSelector((state) => state.hospitalPartnerList.hosPartner);
  const status = useSelector((state) => state.tcPartner.status);

  const [searchValue, setSearchValue] = useState("");
  const [selectedTab, setSelectedTab] = useState("Diagnostics");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    const dataToSend = {
      keyword_search: searchValue,
    };
    dispatch(fetchPartnerList(dataToSend));
  }, [dispatch, searchValue , selectedTab]);

  const maskPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber?.replace(
      /(\d{4})(\d{4})(\d{2})/,
      "$1XXXX$3"
    );
    return maskedNumber;
  };

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
          {/* header part work start */}
          <Grid className="mb10" container alignItems="center">
            {/* text head start */}
            <Grid item xs={4}>
              <h2 className="m0">Hospital TC Partner</h2>
            </Grid>
            <Grid item xs={8} display="flex" justifyContent="flex-end">
              <div className="searchBox">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ "aria-label": "Search" }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => dispatch(fetchTcPartnerList(searchValue))}
                >
                  {" "}
                  <SearchIcon />{" "}
                </IconButton>
              </div>
            </Grid>
            {/* search box end */}
          </Grid>
          {/* header part work end */}

          {/* table work start here */}
          <Paper className="customTable" sx={{ width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => handleTabChange(newValue)}
            >
              {/* <Tab label="Diagnostics" value="Diagnostics" /> */}
              {/* <Tab label="Hospital" value="Hospital" /> */}
            </Tabs>
            <TableContainer sx={{ maxHeight: 385 }}>
              <Table stickyHeader aria-label="sticky">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "red",
                      // borderBottom: "2px solid black",
                      "& th": {
                        fontSize: "0.875rem",
                        color: "#00c2c1",
                      },
                    }}
                  >
                    <TableCell>Partner Name</TableCell>
                    <TableCell>Partner Number</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === "loading" ? ( // Render CircularProgress when status is 'loading'
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : Array.isArray(partnerList) && partnerList.length > 0 ? ( // Check if partnerList is an array and has data
                    partnerList.map((item) => (
                      <TableRow hover key={item._id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{maskPhoneNumber(item.mobile)}</TableCell>
                        <TableCell>{item.city}</TableCell>
                        <TableCell>{item.state}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Stack className="rightPagination mt10" spacing={2}>
              <Pagination color="primary" count={10} shape="rounded" />
            </Stack> */}
          </Paper>
          {/* table work end here */}
        </CardContent>
      </Card>
      {/************ body area end here ************/}
    </>
  );
};

export default OnlyHospitalpartner;
